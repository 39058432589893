import {
  ApiResponse,
  CompletePaymentRequest,
  CompletePaymentResponse,
  CompleteStorePaymentMethodSessionRequest,
  CreateCustomerUserAccount,
  DisableStoredPaymentMethodRequest,
  FlagValues,
  GetCurrentCustomerUserResponse,
  GetPaymentBrandSettingsResponse,
  HostedPaymentSessionRequest,
  HostedPaymentSessionResponse,
  InitStorePaymentMethodSessionResponse,
  LoginCustomerUserAccount,
  LoginCustomerUserAccountResponse,
  LookupInvoiceNumberRequest,
  LookupInvoicesResponse,
  MerchantAccountSettingsResponse,
  PaymentHistoryResponse,
  PaymentSessionUpdateRequest,
  PaymentSessionUpdateResponse,
  PspSessionResponse,
  PspSessionUpdateResponsePayload,
  RequestCustomerUserAccountVerificationCode,
  SendBillingInfoRequest,
  SendBillingInfoResponse,
  SignPfaPayload,
  SignPfaResponse,
  StoredPaymentMethodsResponse,
  UpdateStoredPaymentMethodRequest,
  ValidateEpicAccountRequest,
  ValidateEpicAccountResponse,
} from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import { axiosRetry } from '../util/axiosRetry';
import { getConfig } from '../util/config';

type ApiOptions = {
  signal?: AbortSignal | null;
};
export class ApiClient {
  private static apiClient: { [_: string]: ApiClient } = {};

  private baseUrl: string;
  private token?: string;

  constructor(token?: string) {
    this.baseUrl = getConfig('REACT_APP_PAYMENTS_API_BASE_URL');
    this.token = token;
  }

  static getInstance(token?: string) {
    const key = token || 'default';
    if (!this.apiClient[key]) {
      this.apiClient[key] = new ApiClient(token);
    }
    return this.apiClient[key];
  }

  getAuthHeaders() {
    const customerUserAccessToken = localStorage.getItem('customerUserAccessToken');
    const version = window.location.href.includes('v2') ? 'v2' : 'v1';
    return {
      ...(this.token ? { Authorization: 'Bearer ' + this.token } : {}),
      ...(customerUserAccessToken
        ? {
            'X-Customer-User-Authorization': 'Bearer ' + customerUserAccessToken,
          }
        : {}),
      ...(datadogLogs.getInternalContext()
        ? {
            'x-client-session-id': datadogLogs.getInternalContext()?.session_id,
          }
        : {}),
      'x-ui-version': version,
    };
  }

  async get<TResponse>(url: string, options?: Partial<ApiOptions>): Promise<ApiResponse<TResponse>> {
    try {
      const response = await fetch(`${this.baseUrl}${url}`, {
        method: 'GET',
        signal: options?.signal,
        headers: this.getAuthHeaders(),
      });
      const apiResponse = (await response.json()) as ApiResponse<TResponse>;
      if (Number(apiResponse.status) >= 500) console.error(`GET ${url}: ${apiResponse.status}`, apiResponse);
      else if (Number(apiResponse.status) > 400) console.warn(`GET ${url}: ${apiResponse.status}`, apiResponse);
      return apiResponse;
    } catch (err: any) {
      console.error(`Failed to make GET request to API ${url} with an error: `, err);
      return {
        type: 'network',
        title: 'Network error',
        detail: err.toString(),
        instance: '',
        traceId: '',
        status: 500,
      };
    }
  }

  async postOrPut<TResponse, TData>(
    method: 'POST' | 'PUT',
    url: string,
    body?: TData,
    headers?: { [key: string]: string },
  ): Promise<ApiResponse<TResponse>> {
    try {
      const response = await fetch(`${this.baseUrl}${url}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          ...this.getAuthHeaders(),
          ...headers,
        },

        body: body && JSON.stringify(body),
      });
      const apiResponse = (await response.json()) as ApiResponse<TResponse>;
      if (Number(apiResponse.status) >= 500) console.error(`${method} ${url}: ${apiResponse.status}`, apiResponse);
      else if (Number(apiResponse.status) > 400) console.warn(`${method} ${url}: ${apiResponse.status}`, apiResponse);
      return apiResponse;
    } catch (err: any) {
      console.error(`Failed to make ${method} request to API ${url} with an error: `, err);
      return {
        type: 'network',
        title: 'Network error',
        detail: err.toString(),
        instance: '',
        traceId: '',
        status: 500,
      };
    }
  }

  async post<TResponse, TData>(
    url: string,
    body?: TData,
    headers?: { [key: string]: string },
  ): Promise<ApiResponse<TResponse>> {
    return this.postOrPut<TResponse, TData>('POST', url, body, headers);
  }

  async put<TResponse, TData>(url: string, body: TData): Promise<ApiResponse<TResponse>> {
    return this.postOrPut<TResponse, TData>('PUT', url, body);
  }

  getQueryString(params?: { [name: string]: string | null | undefined }): string {
    if (!params) {
      return '';
    }

    const p = new URLSearchParams();
    let isEmpty = true;
    for (const param of Object.keys(params)) {
      const value = params[param];
      if (value) {
        p.append(param, value);
        isEmpty = false;
      }
    }
    return isEmpty ? '' : `?${p}`;
  }

  async completePayment(payload: CompletePaymentRequest): Promise<CompletePaymentResponse> {
    datadogLogs.logger.info('Calling complete-payment endpoint');
    return await this.post('/flow/complete-payment', payload);
  }

  async initPspSession(): Promise<PspSessionResponse> {
    return await this.post('/flow/psp-session');
  }

  async updatePaymentSession(payload: Partial<PaymentSessionUpdateRequest>): Promise<PaymentSessionUpdateResponse> {
    const retries = 3;
    const interval = 2500;
    const retryOnCodes = [502, 504];

    const updatePaymentSessionMethod = async (): Promise<ApiResponse<PspSessionUpdateResponsePayload>> => {
      return await this.put('/flow/payment-session', payload);
    };
    datadogLogs.logger.info('Starting updatePaymentSession with retries', { retries, interval, retryOnCodes });
    const response = await axiosRetry<PspSessionUpdateResponsePayload>(
      updatePaymentSessionMethod,
      interval,
      retries,
      retryOnCodes,
    );
    if (response.status === 'ok') {
      return response;
    } else {
      return await this.put('/flow/payment-session', payload);
    }
  }

  async getFlowFeatureFlags(): Promise<ApiResponse<FlagValues>> {
    return await this.get(`/flow/featureFlags`);
  }

  async getHppFeatureFlags(token: string | null, subdomain?: string | null): Promise<ApiResponse<FlagValues>> {
    return await this.get(
      `/hosted-payment-page/featureFlags${this.getQueryString({
        token,
        subdomain,
      })}`,
    );
  }

  async getHppAgencyDetail(
    token: string | null | undefined,
    subdomain?: string | null,
  ): Promise<MerchantAccountSettingsResponse> {
    return await this.get(
      `/hosted-payment-page/get-agency-details${this.getQueryString({
        token,
        subdomain,
      })}`,
    );
  }

  async initHppSession(
    token: string,
    payload: Partial<HostedPaymentSessionRequest>,
  ): Promise<HostedPaymentSessionResponse> {
    return await this.post(`/hosted-payment-page/init-payment?token=${token}`, payload);
  }

  async validateEpicAccountCode(
    token: string,
    payload: Partial<ValidateEpicAccountRequest>,
  ): Promise<ValidateEpicAccountResponse> {
    return await this.post('/hosted-payment-page/validate-epic-account-code', payload, { Authorization: token });
  }

  async epicInvoiceLookup(
    token: string,
    payload: Partial<LookupInvoiceNumberRequest>,
    allInvoices: boolean = false,
  ): Promise<LookupInvoicesResponse> {
    return await this.post(
      `/hosted-payment-page/${allInvoices ? 'lookup-all-invoices' : 'lookup-invoice-number'}`,
      payload,
      { Authorization: token },
    );
  }

  async getPaymentHistory(params?: { [name: string]: string | null | undefined }): Promise<PaymentHistoryResponse> {
    const queryParams = this.getQueryString(params);

    return await this.get(`/customer-user/payments${queryParams}`);
  }

  async createCustomerUserAccount(payload: CreateCustomerUserAccount) {
    return this.post('/customer-user-auth', payload);
  }

  async requestCustomerUserVerificationCode(payload: RequestCustomerUserAccountVerificationCode) {
    return this.post('/customer-user-auth/request-verification-code', payload);
  }

  async loginCustomerUserAccount(payload: LoginCustomerUserAccount): Promise<LoginCustomerUserAccountResponse> {
    return this.post('/customer-user-auth/login', payload);
  }

  async getCurrentCustomerUser(): Promise<GetCurrentCustomerUserResponse> {
    return this.get('/customer-user-auth/whoami');
  }

  async getPaymentBrandSettings(): Promise<GetPaymentBrandSettingsResponse> {
    return this.get('/customer-user-auth/brand-settings');
  }

  async getStoredPaymentMethods(): Promise<StoredPaymentMethodsResponse> {
    return this.get('/customer-user/stored-payment-methods');
  }

  async updateStoredPaymentMethods(
    recurringDetailReference: string,
    data: UpdateStoredPaymentMethodRequest,
  ): Promise<StoredPaymentMethodsResponse> {
    return this.post(`/customer-user/stored-payment-methods/${recurringDetailReference}`, data);
  }

  async disableStoredPaymentMethod(recurringDetailReference: string): Promise<ApiResponse<void>> {
    const body: DisableStoredPaymentMethodRequest = {
      recurringDetailReference,
    };

    return this.post('/customer-user/disable-stored-payment-method', body);
  }

  async initStorePaymentMethodPspSession(): Promise<InitStorePaymentMethodSessionResponse> {
    return this.post('/customer-user/init-store-method-session');
  }

  async completeStorePaymentMethodSession(
    payload: CompleteStorePaymentMethodSessionRequest,
  ): Promise<CompletePaymentResponse> {
    return this.post('/customer-user/complete-store-method-session', payload);
  }

  async signPfa(payload: SignPfaPayload): Promise<SignPfaResponse> {
    return this.post('/hosted-payment-page/premium-finance/sign-pfa', payload);
  }

  async sendBillinginfo(payload: SendBillingInfoRequest): Promise<ApiResponse<SendBillingInfoResponse>> {
    return this.post('/hosted-payment-page/premium-finance/send-billing-info', payload);
  }
}

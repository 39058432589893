import {
  calculateSplitFromMinorUnits,
  CurrencyCode,
  LocaleCode,
  PaymentMethod,
  toIntlFormat,
} from '@appliedsystems/payments-core';
import { AvailablePaymentMethod } from 'src/components/HostedPaymentPageContainer/types';

export const toIntlFormatHelper = (amount: number, currency: CurrencyCode, locale: LocaleCode) => {
  if (!amount) return '-';
  return toIntlFormat({ amount, currencyCode: currency }, locale);
};

export const getAmountWithFees = (
  paymentMethod: AvailablePaymentMethod,
  paymentAmount: number, // minor units
  paymentFee: number, // minor $ units if ACH, minor % (eg 350 = 3.5%) if card
  currencyCode: CurrencyCode,
) => {
  switch (paymentMethod) {
    case PaymentMethod.Ach:
      return {
        paymentFee,
        paymentTotal: paymentAmount + paymentFee,
      };
    case PaymentMethod.Card:
      const [calculatedFee] = calculateSplitFromMinorUnits(paymentFee, paymentAmount, currencyCode);
      const paymentTotal = paymentAmount + calculatedFee.amount;

      return {
        paymentFee: calculatedFee.amount,
        paymentTotal: paymentTotal,
      };
  }
};

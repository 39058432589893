import { ApiResponse } from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';

export async function axiosRetry<T>(
  apiRequestMethod: () => Promise<ApiResponse<T>>,
  interval: number,
  retries: number,
  retryOnCodes: number[],
): Promise<ApiResponse<T>> {
  for (let attempt = 0; attempt < retries; attempt++) {
    datadogLogs.logger.info('Attempting API request', { attempt });
    const response = await apiRequestMethod();
    if (response.status === 'ok') {
      datadogLogs.logger.info('API request succeeded', { attempt, response });
      return response;
    }
    if (!retryOnCodes.includes(response.status)) {
      datadogLogs.logger.error('API request failed with non-retryable status', { attempt, status: response.status });
      return response;
    }
    await new Promise((resolve) => setTimeout(resolve, interval));
  }
  datadogLogs.logger.info('Max retries reached');
  return {} as ApiResponse<T>;
}

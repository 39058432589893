/**
 * Adding a translation string:
 *
 * 1. Add it to the `en_US` constant
 * 2. This will produce TS errors for all other languages
 * 3. Add the same entry for other languages, using https://translate.google.com/
 */

import { LocaleCode } from '@appliedsystems/payments-core';

const en_US = {
  LOADING: 'Loading',
  MAKE_A_PAYMENT: 'Make a Payment',
  ENTER_PAYMENT_INFO: 'Enter your payment information below.',
  ENTER_PAYMENT_INFORMATION: 'Enter information below to make a payment.',
  CONTACT_INFORMATION: 'Contact Information',
  PAYMENT_METHOD: 'Payment Method',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  NAME: 'Name',
  BUSINESS_NAME: 'Business Name',
  EMAIL: 'Email Address',
  ORDER_REFERENCE_ID: 'Reference Number',
  PAYMENT_AMOUNT: 'Payment Amount',
  POLICY_NUMBER: 'Policy Number',
  INVOICE_NUMBER: 'Invoice Number',
  AMOUNT: 'Amount',
  PAYMENT_DESCRIPTION: 'Description',
  ORDER_SUMMARY: 'Order Summary',
  PAYMENT_SUMMARY: 'Payment Summary',
  TOTAL_DUE_NOW: 'Total Due Now',
  CURRENCY: 'Currency',
  COPYRIGHT: 'Copyright',
  POLICY_INFORMATION: 'Policy Information',
  CONTINUE_TO_POLICY_INFORMATION: 'Continue to Policy Information',
  CONTINUE_TO_PAYMENT: 'Continue to Payment',
  PAY_NOW: 'Pay Now',
  ERROR_GENERIC: 'Generic Error',
  ERROR_PAYMENT_ERROR: 'Payment Error',
  ERROR_ADYEN_CHECKOUT: 'Checkout Error',
  ERROR_ADYEN_NO_SESSION: 'No Adyen session error',
  ERROR_FIRST_NAME_REQUIRED: 'First name is required',
  ERROR_LAST_NAME_REQUIRED: 'Last name is required',
  ERROR_EMAIL_REQUIRED: 'Email is required',
  ERROR_EMAIL_INVALID: 'Email is invalid',
  ERROR_AMOUNT_REQUIRED: 'Amount is required',
  ERROR_ACCOUNT_NUMBER_REQUIRED: 'Account number is required',
  ERROR_PHONE_NUMBER_REQUIRED: 'Phone number is required',
  ERROR_POSTAL_CODE_REQUIRED: 'Postal code is required',
  ERROR_INVOICE_NUMBER_REQUIRED: 'Invoice number is required',
  PAYMENT_CANCELLED: 'Your payment has been cancelled.',
  ERROR_TOO_MANY_PSP_AUTHORIZATION_ATTEMPTS:
    'Your checkout link/page has exceeded the number of payment attempts (3) permitted and is no longer valid. For your security, you will have to request a new payment link from your Agent or Broker. Thank you!',
  ERROR_DUPLICATE_PAYMENT:
    'This checkout link/page has already been used to process a payment and for security reasons cannot be used again. If you think this is in error, please contact your Agent or Broker immediately for verification. Thank you!',
  ERROR_SESSION_EXPIRED: `Your checkout link/page has not been used within {{paymentSessionExpirationDays}} days and has expired. For your security, you will have to request a new payment link from your Agent or Broker. Thank you!`,
  ERROR_FEE_AMOUNT_BELOW_MINIMUM:
    'The ACH/EFT amount entered is too low to process, the amount entered should be $5.00 or higher to process. Thank you!',
  ERROR_ACH_VALIDATION_FAILED: 'ACH Validation Failed. Please check your details and try again.',
  ERROR_BANK_ACCOUNT_NOT_VALID:
    'We could not verify the information entered, please re-enter your banking information.',
  ERROR_DUPLICATE_PAYMENT_ATTEMPT: 'An existing transaction is already in progress. Please wait momentarily.',
  ERROR_HPP_VALIDATION: 'The account information entered cannot be found. Please verify the details and try again.',
  ERROR_HPP_VALIDATION_INTERNAL:
    'The account information entered cannot be found. Please verify the details and try again. \n\nError code {{id}}',
  ERROR_HPP_VALIDATION_UNKNOWN:
    'The account information entered cannot be found. Please verify the details and try again. \n\nError code {{id}}',
  ERROR_HPP_VALIDATION_NETWORK:
    'The account information entered cannot be found. Please verify the details and try again.',
  ERROR_HPP_VALIDATION_EXCEEDED:
    'The account information could not be verified in the allowed number of attempts. Click "{{buttonName}}" to make a payment without verifying your information.',
  ERROR_HPP_VALIDATION_BYPASS_ALLOWED:
    'The account information could not be verified.  Select the option to proceed without Account Number to continue making a payment.',
  ERROR_HPP_VALIDATION_EXCEEDED_NO_BYPASS:
    'The account information entered could not be verified in the allowed number of attempts. Please contact your agent to make a payment.',
  ERROR_MERCHANT_ACCOUNT_NOT_ACTIVE: 'The merchant account is not active. Please contact your agent to make a payment.',
  REFUSED_ERROR_UNKNOWN: 'Unknown error occurred. Please try again.',
  REFUSED_ERROR_EXPIRED_CARD:
    'The expiration date entered indicates that the credit card has expired, and a valid card is required for payment.',
  REFUSED_ERROR_INVALID_CARD_NUMBER:
    'The card number entered does not match the format or length of a valid credit card number. The user may have mistyped or omitted digits.',
  REFUSED_ERROR_INVALID_CVC:
    'Card Verification Value (CVV) or Card Verification Code (CVC) entered does not match the one associated with the credit card. The CVV/CVC is typically a three- or four-digit security code found on the back or front of the card.',
  REFUSED_ERROR_BILLING_ADDRESS_MISMATCH:
    "The billing address provided does not match the address on file for the credit card. The billing address is used to verify the cardholder's identity and prevent fraudulent transactions.",
  REFUSED_ERROR_FRAUD:
    "Oops! We're currently unable to process your payment. Please try again later or use a different payment method. If you continue to experience issues, please contact customer support for assistance. Thank you!",
  REFUSED_ERROR_REASON: 'Payment refused: {{refusalReason}}.',
  CANCEL_THIS_PAYMENT: 'Cancel this Payment',
  EDIT_THIS_PAYMENT: 'Edit this Payment',
  SAVE: 'Save',
  NEXT: 'Next',
  BACK: 'Back',
  SUBTOTAL: 'Subtotal',
  TOTAL: 'Total',
  CONVENIENCE_FEE: 'Convenience Fee',
  TOTAL_DUE_TODAY: 'Total Due Today',
  BREAKDOWN_DESCRIPTION: 'The total due today is {{totalAmount}}. See the breakdown below and choose a payment method.',
  NON_FINANCED_AMOUNT: 'Non-Financed Amount',
  ACCEPT_CC_FEE: 'I accept the fees associated with the credit card payment',
  CONVENIENCE_FEE_DISCLAIMER_BOLD: 'Disclaimer',
  ACH_DIRECT_DEBIT: 'ACH - Direct Debit',
  ACH_DIRECT_DEBIT_TOOLTIP:
    'This payment option will directly debit the amount due from the bank account you provide. Bank account number and routing number is required for this payment option.',
  CONVENIENCE_FEE_DISCLAIMER:
    'If a Convenience Fee is shown above, our systems platform provider, Applied Systems, Inc., will apply a Non-Refundable fee or charge to the total transaction amount payable by you to the extent you elect to make a payment using a credit or debit card or through an ACH transaction. If you do not wish to pay the convenience fee, please contact {Company_Name} for other payment method options.',
  AGREE_CONVENIENCE_FEE: 'Click here to accept the convenience fee and continue with this payment',
  ACCOUNT_NUMBER: 'Account Number',
  CHOOSE_YOUR_PAYMENT_METHOD: 'Choose your payment method',
  ACCOUNT_NUMBER_TOOLTIP:
    'This can also be referred to as Account Lookup Code on your invoice. If you need further assistance contact your agent.',
  PROCEED_WITHOUT_ACCOUNT_NUMBER: 'Proceed without Account Number',
  PHONE_NUMBER: 'Phone Number',
  POSTAL_CODE: 'Postal Code',
  SINGLE_AMOUNT: 'Single Amount',
  PAY_BY_INVOICE: 'Pay by Invoice',
  DUE_DATE: 'Due Date',
  AMOUNT_DUE: 'Amount Due',
  PREMIUM_FINANCE_OFFER_TABLE_HEADER: 'Premium Finance Offer',
  // eslint-disable-next-line no-template-curly-in-string
  PREMIUM_FINANCE_OFFER_TABLE_BODY:
    'Pay later with {{downPaymentAmount}} and {{numberOfInstallments}} {{installmentFrequency}} payments of {{installmentAmount}}.',
  INSTALLMENT_FREQUENCY_TYPE_MONTHLY: 'monthly ',
  INSTALLMENT_FREQUENCY_TYPE_QUARTERLY: 'quarterly ',
  ITEM_NUMBER: 'Item Number',
  DESCRIPTION: 'Description',
  ACCOUNT_INFORMATION: 'Account Information',
  ENTER_YOUR_ACCOUNT_DETAILS: 'Enter your account details below.',
  INVOICE_SELECTION: 'Invoice Selection',
  SELECT_INVOICES_TO_PAY: 'Select the invoices you would like to pay.',
  REVIEW_THE_ITEMS_BELOW: 'Review the items below and enter your payment information.',
  PAYMENT_AGAINST_INVOICE: 'Payment against invoice(s)',
  ERROR_FAILED_TO_LOAD_PAYMENT_METHODS: "Couldn't load stored payment methods",
  ACCOUNT_SETTINGS_MANAGE_ACCOUNT: `Manage Your Account`,
  ACCOUNT_SETTINGS_BACK: `Back to Make a Payment`,
  ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_METHODS: `Payment Methods`,
  ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_HISTORY: `Payment History`,
  ACCOUNT_SETTINGS_TAB_TITLE_MANAGE_AUTOPAY: `Manage Autopay`,
  ACCOUNT_SETTINGS_PH_DATEFILTER_ALL_TIME: `All Time`,
  ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_12: `Last 12 Months`,
  ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_30: `Last 30 Days`,
  ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_7: `Last 7 Days`,
  ACCOUNT_SETTINGS_PH_GRID_DATE: `Date`,
  ACCOUNT_SETTINGS_PH_GRID_INVOICE_NUMBER: `Invoice Number`,
  ACCOUNT_SETTINGS_PH_GRID_DESCRIPTION: `Description`,
  ACCOUNT_SETTINGS_PH_GRID_AMOUNT: `Payment Amount`,
  ACCOUNT_SETTINGS_PH_NOTICE: `Please note the payments represented in this list may not be all payments made, or inclusive of pending payments, unapplied payments or non-digital payments made. Missing payments should be addressed with your account representative.`,
  PAY: 'Pay',
  YOUR_TOTAL: 'Your Total',
  FEE: 'Fee',
  LOG_IN: 'Log In',
  CREATE_ACCOUNT: 'Create an Account',
  USE_PAYMENT_METHOD_ON_FILE: 'Use Payment Method on File',
  YOUR_ACCOUNT_HAS_BEEN_CREATED: 'Your account has been successfully created!',
  ENTER_VERIFICATION_CODE: 'Please check your email for the verification code and enter it below',
  RESEND_CODE: 'Resend Code',
  VERIFICATION_CODE: 'Verification Code',
  VERIFICATION_CODE_REQUIRED: 'Verification code is required',
  VERIFICATION_CODE_NOT_VALID: 'Verification code is not valid or expired',
  LOGIN_MODAL_TO_RECEIVE_CODE: 'To receive a verification code, you must first',
  DONT_RECEIVE_VERIFICATION_CODE:
    "If you haven't received a code, this may indicate that an account has not been created. Please proceed without logging in or create an account.",
  CANCEL: 'Cancel',
  ENTER_YOUR_EMAIL: 'Please enter in your email',
  DONT_HAVE_ACCOUNT: "Don't have an account yet?",
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  SUCCESS: 'Success!',
  ERROR: 'Error',
  ERROR_ACCOUNT_NUMBER_NOT_VALID: 'Account number is not valid',
  ACCOUNT_CREATED_SUCCESSFULLY: 'Account created successfully',
  FAILED_TO_CREATE_ACCOUNT: 'Failed to create account',
  LOGIN_SUCCESSFUL: 'You have successfully logged in',
  FAILED_TO_LOGIN: 'Failed to login',
  TOO_MANY_ATTEMPTS: 'Too many attempts. Please try again a bit later.',
  FAILED_TO_SEND_VERIFICATION_CODE: 'Failed to send verification code',
  SEARCH: 'Search',
  DOWNLOAD_TRANSACTIONS_HISTORY: 'Download Transaction History',
  STORED_PAYMENT_METHOD_DELETED: 'Stored payment method has been successfully deleted',
  FAILED_TO_DELETE_STORED_PAYMENT_METHOD: 'Failed to delete stored payment method',
  PAYMENT_METHOD_CARDHOLDER_NAME: 'Cardholder Name',
  PAYMENT_METHOD_CARD_NUMBER: 'Card Number',
  PAYMENT_METHOD_EXPIRY: 'Expiry',
  PAYMENT_METHOD_CARD_TYPE: 'Type',
  NO_DATA: 'No Data',
  REMOVE_PAYMENT_METHOD: 'Remove Payment Method',
  PAYMENT_METHOD_OWNER_NAME: 'Owner Name',
  PAYMENT_METHOD_ACCOUNT_NICKNAME: 'Account Nickname',
  PAYMENT_METHOD_ACCOUNT_NUMBER: 'Account Number',
  PAYMENT_METHOD_ROUTING_NUMBER: 'Bank Name',
  DELETE: 'Delete',
  DELETE_PAYMENT_METHOD_CONFIRMATION_TITLE: 'Delete Stored Payment Method',
  DELETE_PAYMENT_METHOD_CONFIRMATION: 'Are you sure you want to delete this stored payment method?',
  PAYMENT_CONFIRMATION: 'Thank you for your payment of {{totalAmount}}',
  PAYMENT_CONFIRMATION_DESCRIPTION: `A receipt for payment has been sent to {{email}} for your records. Below is a summary of your payment.`,
  INVOICE: 'Invoice',
  TOTAL_PAID: 'Total Paid',
  PAID: 'Paid',
  PRINT_CONFIRMATION: 'Print Confirmation',
  CONFIRMATION_SUCCESS_ALT_TEXT: 'Payment Successful Checkmark',
  ENDING_WITH: 'ending {{lastFourDigits}}',
  PAYMENT_METHOD_NICKNAME: 'Payment Method Nickname',
  EDIT_STORED_PAYMENT_METHOD: 'Edit Payment Method',
  EDIT_STORED_PAYMENT_METHOD_NICKNAME: 'Edit Nickname',
  ADD_PAYMENT_METHOD: 'Add Payment Method',
  ACH: 'ACH',
  CREDIT_CARDS: 'Credit Cards',
  CREDIT_CARD: 'Credit Card',
  SELECT_A_CARD: 'Select a Card',
  SELECT_AN_ACCOUNT: 'Select an ACH Account',
  ENTER_A_NEW_PAYMENT_METHOD: 'Enter a new payment method',
  PAYMENT_METHOD_ADDED: 'Payment method has been successfully added',
  PAYMENT_METHOD_FAILED_TO_ADD: 'Failed to add payment method',
  PAYMENT_METHOD_FAIL_TO_INITIALIZE_FORM: 'Failed to initialize form',
  PAYMENT_FLOW_SESSION_ID_MISSING: 'Payment session ID is missing',
  PAYMENT_FLOW_SESSION_FAILED_UNKNOWN: 'Failed to init psp session with response',
  PAYMENT_FLOW_SESSION_ERROR_UNKNOWN: 'Failed to init psp session with error',
  INIT_ADYEN_CHECKOUT_FAILED: 'Failed to init checkout with an error',
  RECAPTCHA_VERIFICATION_FAILED: 'Recaptcha is not ready',
  RECAPTCHA_BROWSER_ERROR: 'Recaptcha is having problems with your browser. Please try a different browser.',
  RECAPTCHA_BROWSER_ERROR_WITH_ID:
    'Recaptcha is having problems with your browser. Please try a different browser. \n\nError code {{id}}',
  FIELD_VALIDATION_FAILED: 'Field validation failed',
  PSP_SESSION_UPDATE_FAILED: 'PSP session update failed',
  AGENCY_DETAILS_MISSING: 'Agency details missing',
  FETCH_AGENCY_DETAIL_FAILED_UNKNOWN: 'Failed to get agency details with response',
  FETCH_AGENCY_DETAIL_FAILED_INTERNAL: 'Failed to get agency details with response',
  FETCH_AGENCY_DETAIL_FAILED_NETWORK: 'Failed to get agency details with response',
  UNEXPECTED_ERROR_CODE: 'Unexpected Error #{{code}} \n\nError code {{id}}',
  ERROR_MAKE_PAYMENT_INTERNAL: 'There was an error making the payment',
  ERROR_MAKE_PAYMENT_UNKNOWN: 'There was an error making the payment',
  ERROR_MAKE_PAYMENT_NETWORK: 'There was an error making the payment',
  FETCH_AGENCY_EMPTY_RESPONSE: 'Failed to fetch agency details: empty response',
  FETCH_AGENCY_FAILED: 'Fialed to fetch agency details with error',
  GET_HPP_SESSION_FAILED_TOKEN_MISSING: 'Get HPP session failed: token missing',
  GET_HPP_SESSION_FAILED_UNKNOWN: 'Failed to get HPP session with response',
  GET_HPP_SESSION_EMPTY_RESPONSE: 'Failed to get HPP session: empty response',
  GET_HPP_SESSION_ERROR_UNKNOWN: 'Failed to get HPP session with error',
  GET_HPP_SESSION_ERROR_INTERNAL: 'Failed to get HPP session with error {{id}}',
  GET_HPP_SESSION_ERROR_NETWORK: 'Failed to get HPP session with error',
  UPDATE_HPP_SESSION_ERROR_NETWORK: 'Failed to update Flow session with network error',
  UPDATE_HPP_SESSION_ERROR_INTERNAL: 'Failed to update Flow session with internal error {{id}}',
  UPDATE_HPP_SESSION_ERROR_UNKNOWN: 'Failed to update Flow session with unknown error',
  VALIDATION_HANDLER_NOT_SET: 'Validation handler not set',
  SELECT_PAY_BY_OPTION: 'Select a pay by option and enter the required information below to proceed with your payment',
  NO_SELECT_PAY_BY_OPTION: 'Enter the required information below to proceed with your payment.',
  PAY_BY: 'Pay By',
  ERROR_PAYMENT_INITIATION: 'Unable to initiate a payment session.',
  ERROR_TRY: 'Try:',
  ERROR_CHECK_INTERNET: 'Checking your internet connection',
  ERROR_CLEAR_CACHE: 'Clearing browser cache',
  ERROR_TURN_OFF_FIREWALL: 'Turning off your firewall',
  ERROR_CONTACT_IT:
    'If you are on a business-issued system or VPN, and these steps do not resolve the issue, please contact your IT team.',
  PREMIUM_FINANCE_UNAVAILABLE: 'Premium Finance Unavailable',
  PREMIUM_FINANCE_UNAVAILABLE_DESCRIPTION:
    'Premium finance will not be available as a payment method as the amount due exceeds $100,000. If you wish to premium finance, contact your agent or go back and update your selection. Otherwise you may continue to payment options to pay in full.',
  PAY_WITH_FINANCING: 'Pay with Financing',
  PAY_IN_FULL_TODAY: 'Pay in Full Today',
  PAY_WITH_FINANCING_TOOLTIP:
    'This payment option will allow you to set up financing for eligible policies and pay in full for policies that are ineligible for financing.',
  PAYMENTS: 'Payments',
  MONTHLY_PAYMENTS: 'Monthly Payments',
  QUARTERLY_PAYMENTS: 'Quarterly Payments',
  EST_DOWN_PAYMENT: 'Est. Down Payment',
  CONTINUE_TO_DOWN_PAYMENT: 'Continue to Down Payment',
  PAYMENT_OPTIONS: 'Payment Options',
  DOWNPAYMENT: 'Down Payment',
  DOWNPAYMENT_DESCRIPTION: 'Down Payment for Financing',
  RETAINED_PAYMENTS_DESCRIPTION: 'Retained Payments',
  RETAINED_PAYMENTS: 'Retained Payments',
  TBD: 'TBD',
  REVIEW_AND_SIGN_DESCRIPTION:
    'Review and sign the premium finance agreement by clicking the "Review and Sign Agreement" button below. Then continue to the downpayment.',
  PREMIUM_FINANCE_AGREEMENT: 'Premium Finance Agreement',
  REVIEW_AND_SIGN_BUTTON: 'Review and Sign Agreement',
  FINANCING_SUMMARY_TITLE: 'Financing Summary',
  FINANCING_SUMMARY_DESCRIPTION:
    'Below is a summary of your financing terms with {{company}}. After the premium finance agreement has been signed use the button below to set up payment installments. Premium financing will be bound only after a down payment has been made. If you have any questions contact your agent.',
  SET_UP_PAYMENTS_INSTALLMENTS: 'Set Up Payment Installments',
  FINANCE_COMPANY: 'Finance Company',
  FINANCE_QUOTE_NUMBER: 'Finance Quote No.',
  AMOUNT_FINANCED: 'Amount Financed',
  INSTALLMENT_AMOUNT: 'Installment Amount',
  MONTHLY_AMOUNT: 'Monthly Amount',
  QUARTERLY_AMOUNT: 'Quarterly Amount',
  NUMBER_OF_PAYMENTS: 'No. of Payments',
  NEXT_PAYMENT_DUE: 'Next Payment Due',
  FINANCING_AVAILABLE: 'Financing Available',
  DUE_IN_FULL: 'Due in Full',
  REVIEW_PREMIUM_FINANCE: 'Review and Sign Finance Agreement',
  ASSERT_PF_AGREEMENT:
    'I assert that I have reviewed the agreement and agree to all the terms set forth in the premium finance agreement.',
  ENTER_NAME_NUMBER: 'Enter your name and phone number to submit your electronic signature.',
  AGREE_SIGN: 'Agree and Sign',
  SIGNED_SUCCESSFULLY: 'Signed Successfully',
  SET_UP_PAYMENT_INSTALLMENTS: 'Set Up Payment Installments',
  SET_UP_PAYMENT_INSTALLMENTS_DESCRIPTION:
    'Enter your payment details below to set up installment payments with the finance company. The payments will be automatically debited from the account you provide. Note that the payments will be handled directly by the finance company, not the insurance agency.',
  ACCOUNT_HOLDER_NAME: 'Account Holder Name',
  CONFIRM_ACCOUNT_NUMBER: 'Confirm Account Number',
  ACCOUNT_NUMBER_MISMATCH: 'Account number fields do not match',
  ABA_ROUTING_NUMBER: 'ABA Routing Number',
  CONFIRM_ABA_ROUTING_NUMBER: 'Confirm ABA Routing Number',
  ABA_ROUTING_NUMBER_MISMATCH: 'ABA Routing Number fields do not match',
  PAYMENT_INSTALLMENTS_AUTHORIZATION_DESCRIPTION:
    'I authorize {{company}} to debit {{installmentAmount}} from my bank account {{frequency}} until the premium balance is $0.00.',
  PAYMENT_INSTALLMENTS_AUTHORIZATION_DESCRIPTION_WITH_ACH_AGREEMENT:
    'I agree to the <termsLink>Terms and Conditions</termsLink> of the Commercial ACH Debit Authorization as per {{company}}.',
  CLICK_AND_REVIEW_TERMS: 'Click and review the Terms and Conditions to proceed.',
  SUBMIT: 'Submit',
  AUTHORIZE_PAYMENT_INSTALLMENTS_REQUIRED: 'You must authorize payment installments in order to proceed',
  PF_PAYMENT_CONFIRMATION: 'Thank you for your payment of {{paidTotal}}.',
  PF_PAYMENT_CONFIRMATION_DESCRIPTION:
    'A receipt for payment and a copy of the premium finance agreement has been sent to {{email}} for your records.',
  PF_PAYMENT_AGREEMENT_TERMS:
    'Below is a summary of the financing terms. To set up monthly payments, use the button below or follow the instructions provided in the confirmation email. If you have any questions contact your agent.',
  DOWNLOAD_PF_AGREEMENT: 'Download Premium Finance Agreement',
  FINANCING_SUMMARY: 'Financing Summary',
  TOTAL_PAID_TODAY: 'Total Paid Today',
  DOWN_PAYMENT_METHOD: 'Down payment method',
  BELOW_FINANCE_SUMMARY: 'Below is a summary of the financing terms.',
  DOWN_PAYMENT: 'Down Payment',
} as const;

export type Translation = { [_ in keyof typeof en_US]: string };

export const getLocale = (locale: string | null, defaultValue: LocaleCode = LocaleCode.en_US) => {
  return Object.entries(LocaleCode).find(([key, value]) => key === locale || value === locale)?.[1] || defaultValue;
};

const textToKeyMap: { [_: string]: keyof Translation } = {
  'Policy Number': 'POLICY_NUMBER',
  'Invoice Number': 'INVOICE_NUMBER',
};

export const textToLocaleKey = (text: string): keyof Translation | string => {
  return textToKeyMap[text] || text;
};

export const translations: { [_ in LocaleCode]: Translation } = {
  [LocaleCode.en_US]: en_US,
  [LocaleCode.en_CA]: {
    ...en_US,
    LOADING: 'Loading',
    MAKE_A_PAYMENT: 'Make a Payment',
    ENTER_PAYMENT_INFO: 'Enter your payment information below.',
    ENTER_PAYMENT_INFORMATION: 'Enter information below to make a payment.',
    CONTACT_INFORMATION: 'Contact Information',
    PAYMENT_METHOD: 'Payment Method',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    NAME: 'Name',
    BUSINESS_NAME: 'Business Name',
    EMAIL: 'Email Address',
    ORDER_REFERENCE_ID: 'Reference Number',
    PAYMENT_AMOUNT: 'Payment Amount',
    POLICY_NUMBER: 'Policy Number',
    INVOICE_NUMBER: 'Invoice Number',
    AMOUNT: 'Amount',
    PAYMENT_DESCRIPTION: 'Description',
    ORDER_SUMMARY: 'Order Summary',
    PAYMENT_SUMMARY: 'Payment Summary',
    TOTAL_DUE_NOW: 'Total Due Now',
    CURRENCY: 'Currency',
    COPYRIGHT: 'Copyright',
    POLICY_INFORMATION: 'Policy Information',
    CONTINUE_TO_POLICY_INFORMATION: 'Continue to Policy Information',
    CONTINUE_TO_PAYMENT: 'Continue to Payment',
    PAY_NOW: 'Pay Now',
    ERROR_GENERIC: 'Generic Error',
    ERROR_PAYMENT_ERROR: 'Payment Error',
    ERROR_ADYEN_CHECKOUT: 'Checkout Error',
    ERROR_ADYEN_NO_SESSION: 'No Adyen session error',
    ERROR_FIRST_NAME_REQUIRED: 'First name is required',
    ERROR_LAST_NAME_REQUIRED: 'Last name is required',
    ERROR_EMAIL_REQUIRED: 'Email is required',
    ERROR_EMAIL_INVALID: 'Email is invalid',
    ERROR_AMOUNT_REQUIRED: 'Amount is required',
    ERROR_ACCOUNT_NUMBER_REQUIRED: 'Account number is required',
    ERROR_PHONE_NUMBER_REQUIRED: 'Phone number is required',
    ERROR_POSTAL_CODE_REQUIRED: 'Postal code is required',
    ERROR_INVOICE_NUMBER_REQUIRED: 'Invoice number is required',
    PAYMENT_CANCELLED: 'Your payment has been cancelled.',
    ERROR_TOO_MANY_PSP_AUTHORIZATION_ATTEMPTS:
      'Your checkout link/page has exceeded the number of payment attempts (3) permitted and is no longer valid. For your security, you will have to request a new payment link from your Agent or Broker. Thank you!',
    ERROR_DUPLICATE_PAYMENT:
      'This checkout link/page has already been used to process a payment and for security reasons cannot be used again. If you think this is in error, please contact your Agent or Broker immediately for verification. Thank you!',
    ERROR_SESSION_EXPIRED: `Your checkout link/page has not been used within {{paymentSessionExpirationDays}} days and has expired. For your security, you will have to request a new payment link from your Agent or Broker. Thank you!`,
    ERROR_FEE_AMOUNT_BELOW_MINIMUM:
      'The ACH/EFT amount entered is too low to process, the amount entered should be $5.00 or higher to process. Thank you!',
    ERROR_ACH_VALIDATION_FAILED: 'ACH Validation Failed. Please check your details and try again.',
    ERROR_BANK_ACCOUNT_NOT_VALID:
      'We could not verify the information entered, please re-enter your banking information.',
    ERROR_DUPLICATE_PAYMENT_ATTEMPT: 'An existing transaction is already in progress. Please wait momentarily.',
    ERROR_HPP_VALIDATION: 'The account information entered cannot be found. Please verify the details and try again.',
    ERROR_HPP_VALIDATION_BYPASS_ALLOWED:
      'The account information could not be verified.  Select the option to proceed without Account Number to continue making a payment.',
    ERROR_HPP_VALIDATION_INTERNAL:
      'The account information entered cannot be found. Please verify the details and try again. {{id}}',
    ERROR_HPP_VALIDATION_UNKNOWN:
      'The account information entered cannot be found. Please verify the details and try again. {{id}}',
    ERROR_HPP_VALIDATION_NETWORK:
      'The account information entered cannot be found. Please verify the details and try again.',
    ERROR_HPP_VALIDATION_EXCEEDED:
      'The account information could not be verified in the allowed number of attempts. Click "{{buttonName}}" to make a payment without verifying your information.',
    ERROR_HPP_VALIDATION_EXCEEDED_NO_BYPASS:
      'The account information entered could not be verified in the allowed number of attempts. Please contact your agent to make a payment.',
    REFUSED_ERROR_UNKNOWN: 'Unknown error occurred. Please try again.',
    REFUSED_ERROR_EXPIRED_CARD:
      'The expiration date entered indicates that the credit card has expired, and a valid card is required for payment.',
    REFUSED_ERROR_INVALID_CARD_NUMBER:
      'The card number entered does not match the format or length of a valid credit card number. The user may have mistyped or omitted digits.',
    REFUSED_ERROR_INVALID_CVC:
      'Card Verification Value (CVV) or Card Verification Code (CVC) entered does not match the one associated with the credit card. The CVV/CVC is typically a three- or four-digit security code found on the back or front of the card.',
    REFUSED_ERROR_BILLING_ADDRESS_MISMATCH:
      "The billing address provided does not match the address on file for the credit card. The billing address is used to verify the cardholder's identity and prevent fraudulent transactions.",
    REFUSED_ERROR_FRAUD:
      "Oops! We're currently unable to process your payment. Please try again later or use a different payment method. If you continue to experience issues, please contact customer support for assistance. Thank you!",
    CANCEL_THIS_PAYMENT: 'Cancel this Payment',
    EDIT_THIS_PAYMENT: 'Edit this Payment',
    SAVE: 'Save',
    NEXT: 'Next',
    BACK: 'Back',
    SUBTOTAL: 'Subtotal',
    TOTAL: 'Total',
    CONVENIENCE_FEE: 'Convenience Fee',
    TOTAL_DUE_TODAY: 'Total Due Today',
    BREAKDOWN_DESCRIPTION:
      'The total due today is <strong>{{totalAmount}}</strong>. See the breakdown below and choose a payment method.',
    NON_FINANCED_AMOUNT: 'Non-Financed Amount',
    CONVENIENCE_FEE_DISCLAIMER_BOLD: 'Disclaimer',
    ACH_DIRECT_DEBIT: 'ACH - Direct Debit',
    ACH_DIRECT_DEBIT_TOOLTIP:
      'This payment option will directly debit the amount due from the bank account you provide. Bank account number and routing number is required for this payment option.',
    ACCEPT_CC_FEE: 'I accept the fees associated with the credit card payment',
    CONVENIENCE_FEE_DISCLAIMER:
      'If a Convenience Fee is shown above, our systems platform provider, Applied Systems, Inc., will apply a Non-Refundable fee or charge to the total transaction amount payable by you to the extent you elect to make a payment using a credit card. If you do not wish to pay the convenience fee, please contact {Company_Name} for other payment method options.',
    AGREE_CONVENIENCE_FEE: 'Click here to accept the convenience fee and continue with this payment',
    ACCOUNT_NUMBER: 'Account Number',
    CHOOSE_YOUR_PAYMENT_METHOD: 'Choose your payment method',
    ACCOUNT_NUMBER_TOOLTIP:
      'This can also be referred to as Account Lookup Code on your invoice. If you need further assistance contact your broker.',
    PROCEED_WITHOUT_ACCOUNT_NUMBER: 'Proceed without Account Number',
    PHONE_NUMBER: 'Phone Number',
    POSTAL_CODE: 'Postal Code',
    SINGLE_AMOUNT: 'Single Amount',
    PAY_BY_INVOICE: 'Pay by Invoice',
    DUE_DATE: 'Due Date',
    AMOUNT_DUE: 'Amount Due',
    PREMIUM_FINANCE_OFFER_TABLE_HEADER: 'Premium Finance Offer',
    // eslint-disable-next-line no-template-curly-in-string
    PREMIUM_FINANCE_OFFER_TABLE_BODY:
      'Pay later with {{downPaymentAmount}} and {{numberOfInstallments}} {{installmentFrequency}} payments of {{installmentAmount}}.',
    INSTALLMENT_FREQUENCY_TYPE_MONTHLY: 'monthly ',
    INSTALLMENT_FREQUENCY_TYPE_QUARTERLY: 'quarterly ',
    ITEM_NUMBER: 'Item Number',
    DESCRIPTION: 'Description',
    ACCOUNT_INFORMATION: 'Account Information',
    ENTER_YOUR_ACCOUNT_DETAILS: 'Enter your account details below.',
    INVOICE_SELECTION: 'Invoice Selection',
    SELECT_INVOICES_TO_PAY: 'Select the invoices you would like to pay.',
    REVIEW_THE_ITEMS_BELOW: 'Review the items below and enter your payment information.',
    PAYMENT_AGAINST_INVOICE: 'Payment against invoice(s)',
    ERROR_FAILED_TO_LOAD_PAYMENT_METHODS: "Couldn't load stored payment methods",
    ACCOUNT_SETTINGS_MANAGE_ACCOUNT: `Manage Your Account`,
    ACCOUNT_SETTINGS_BACK: `Back to Make a Payment`,
    ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_METHODS: `Payment Methods`,
    ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_HISTORY: `Payment History`,
    ACCOUNT_SETTINGS_TAB_TITLE_MANAGE_AUTOPAY: `Manage Autopay`,
    ACCOUNT_SETTINGS_PH_DATEFILTER_ALL_TIME: `All Time`,
    ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_12: `Last 12 Months`,
    ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_30: `Last 30 Days`,
    ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_7: `Last 7 Days`,
    ACCOUNT_SETTINGS_PH_GRID_DATE: `Date`,
    ACCOUNT_SETTINGS_PH_GRID_INVOICE_NUMBER: `Invoice Number`,
    ACCOUNT_SETTINGS_PH_GRID_DESCRIPTION: `Description`,
    ACCOUNT_SETTINGS_PH_GRID_AMOUNT: `Payment Amount`,
    ACCOUNT_SETTINGS_PH_NOTICE: `Please note the payments represented in this list may not be all payments made, or inclusive of pending payments, unapplied payments or non-digital payments made. Missing payments should be addressed with your account representative.`,
    PAY: 'Pay',
    YOUR_TOTAL: 'Your Total',
    FEE: 'Fee',
    LOG_IN: 'Log In',
    CREATE_ACCOUNT: 'Create an Account',
    USE_PAYMENT_METHOD_ON_FILE: 'Use Payment Method on File',
    YOUR_ACCOUNT_HAS_BEEN_CREATED: 'Your account has been successfully created!',
    ENTER_VERIFICATION_CODE: 'Please check your email for the verification code and enter it below',
    RESEND_CODE: 'Resend Code',
    VERIFICATION_CODE: 'Verification Code',
    VERIFICATION_CODE_REQUIRED: 'Verification code is required',
    VERIFICATION_CODE_NOT_VALID: 'Verification code is not valid or expired',
    LOGIN_MODAL_TO_RECEIVE_CODE: 'To receive a verification code, you must first',
    DONT_RECEIVE_VERIFICATION_CODE:
      "If you haven't received a code, this may indicate that an account has not been created. Please proceed without logging in or create an account.",
    CANCEL: 'Cancel',
    ENTER_YOUR_EMAIL: 'Please enter in your email',
    DONT_HAVE_ACCOUNT: "Don't have an account yet?",
    ALREADY_HAVE_ACCOUNT: 'Already have an account?',
    SUCCESS: 'Success!',
    ERROR: 'Error',
    ERROR_ACCOUNT_NUMBER_NOT_VALID: 'Account number is not valid',
    ACCOUNT_CREATED_SUCCESSFULLY: 'Account created successfully',
    FAILED_TO_CREATE_ACCOUNT: 'Failed to create account',
    LOGIN_SUCCESSFUL: 'You have successfully logged in',
    FAILED_TO_LOGIN: 'Failed to login',
    TOO_MANY_ATTEMPTS: 'Too many attempts. Please try again a bit later.',
    FAILED_TO_SEND_VERIFICATION_CODE: 'Failed to send verification code',
    SEARCH: 'Search',
    DOWNLOAD_TRANSACTIONS_HISTORY: 'Download Transaction History',
    PAYMENT_FLOW_SESSION_ID_MISSING: 'Payment session ID is missing',
    PAYMENT_FLOW_SESSION_FAILED_UNKNOWN: 'Failed to init psp session with response',
    PAYMENT_CONFIRMATION: 'Thank you for your payment of {{totalAmount}}',
    PAYMENT_CONFIRMATION_DESCRIPTION: `A receipt for payment has been sent to {{email}} for your records. Below is a summary of your payment.`,
    INVOICE: 'Invoice',
    TOTAL_PAID: 'Total Paid',
    PAID: 'Paid',
    PRINT_CONFIRMATION: 'Print Confirmation',
    CONFIRMATION_SUCCESS_ALT_TEXT: 'Payment Successful Checkmark',
    ENDING_WITH: 'ending {{lastFourDigits}}',
    PAYMENT_FLOW_SESSION_ERROR_UNKNOWN: 'Failed to init psp session with error',
    INIT_ADYEN_CHECKOUT_FAILED: 'Failed to init checkout with an error',
    RECAPTCHA_VERIFICATION_FAILED: 'Recaptcha is not ready',
    RECAPTCHA_BROWSER_ERROR: 'Recaptcha is having problems with your browser. Please try a different browser.',
    FIELD_VALIDATION_FAILED: '',
    PSP_SESSION_UPDATE_FAILED: 'PSP session update failed',
    AGENCY_DETAILS_MISSING: 'Agency details missing',
    FETCH_AGENCY_DETAIL_FAILED_UNKNOWN: 'Failed to get agency details with response',
    FETCH_AGENCY_DETAIL_FAILED_INTERNAL: 'Failed to get agency details with response',
    FETCH_AGENCY_DETAIL_FAILED_NETWORK: 'Failed to get agency details with response',
    FETCH_AGENCY_EMPTY_RESPONSE: 'Failed to fetch agency details: empty response',
    FETCH_AGENCY_FAILED: 'Fialed to fetch agency details with error',
    GET_HPP_SESSION_FAILED_TOKEN_MISSING: 'Get HPP session failed: token missing',
    GET_HPP_SESSION_FAILED_UNKNOWN: 'Failed to get HPP session with response',
    GET_HPP_SESSION_EMPTY_RESPONSE: 'Failed to get HPP session: empty response',
    GET_HPP_SESSION_ERROR_UNKNOWN: 'Failed to get HPP session with error',
    GET_HPP_SESSION_ERROR_INTERNAL: 'Failed to get HPP session with error {{id}}',
    GET_HPP_SESSION_ERROR_NETWORK: 'Failed to get HPP session with error',
    UPDATE_HPP_SESSION_ERROR_NETWORK: 'Failed to update Flow session with network error',
    UPDATE_HPP_SESSION_ERROR_INTERNAL: 'Failed to update Flow session with internal error {{id}}',
    UPDATE_HPP_SESSION_ERROR_UNKNOWN: 'Failed to update Flow session with unknown error',
    VALIDATION_HANDLER_NOT_SET: 'Validation handler not set',
    SELECT_PAY_BY_OPTION:
      'Select a pay by option and enter the required information below to proceed with your payment',
    NO_SELECT_PAY_BY_OPTION: 'Enter the required information below to proceed with your payment.',
    PAY_BY: 'Pay By',
    ERROR_PAYMENT_INITIATION: 'Unable to initiate a payment session.',
    ERROR_TRY: 'Try:',
    ERROR_CHECK_INTERNET: 'Checking your internet connection',
    ERROR_CLEAR_CACHE: 'Clearing browser cache',
    ERROR_TURN_OFF_FIREWALL: 'Turning off your firewall',
    ERROR_CONTACT_IT:
      'If you are on a business-issued system or VPN, and these steps do not resolve the issue, please contact your IT team.',
    PREMIUM_FINANCE_UNAVAILABLE: 'Premium Finance Unavailable',
    PREMIUM_FINANCE_UNAVAILABLE_DESCRIPTION:
      'Premium finance will not be available as a payment method as the amount due exceeds $100,000. If you wish to premium finance, contact your agent or go back and update your selection. Otherwise you may continue to payment options to pay in full.',
    PAY_WITH_FINANCING: 'Pay with Financing',
    PAY_IN_FULL_TODAY: 'Pay in Full Today',
    PAY_WITH_FINANCING_TOOLTIP:
      'This payment option will allow you to set up financing for eligible policies and pay in full for policies that are ineligible for financing.',
    PAYMENTS: 'Payments',
    MONTHLY_PAYMENTS: 'Monthly Payments',
    QUARTERLY_PAYMENTS: 'Quarterly Payments',
    EST_DOWN_PAYMENT: 'Est. Down Payment',
    CONTINUE_TO_DOWN_PAYMENT: 'Continue to Down Payment',
    PAYMENT_OPTIONS: 'Payment Options',
    DOWNPAYMENT: 'Down Payment',
    DOWNPAYMENT_DESCRIPTION: 'Down Payment for Financing',
    RETAINED_PAYMENTS_DESCRIPTION: 'Retained Payments',
    RETAINED_PAYMENTS: 'Retained Payments',
    TBD: 'TBD',
    REVIEW_AND_SIGN_DESCRIPTION:
      'Review and sign the premium finance agreement by clicking the "Review and Sign Agreement" button below. Then continue to the downpayment.',
    PREMIUM_FINANCE_AGREEMENT: 'Premium Finance Agreement',
    REVIEW_AND_SIGN_BUTTON: 'Review and Sign Agreement',
    FINANCING_SUMMARY_TITLE: 'Financing Summary',
    FINANCING_SUMMARY_DESCRIPTION:
      'Below is a summary of your financing terms with {{company}}. After the premium finance agreement has been signed use the button below to set up payment installments. Premium financing will be bound only after a down payment has been made. If you have any questions contact your agent.',
    SET_UP_PAYMENTS_INSTALLMENTS: 'Set Up Payment Installments',
    FINANCE_COMPANY: 'Finance Company',
    FINANCE_QUOTE_NUMBER: 'Finance Quote No',
    AMOUNT_FINANCED: 'Amount Financed',
    INSTALLMENT_AMOUNT: 'Installment Amount',
    MONTHLY_AMOUNT: 'Monthly Amount',
    QUARTERLY_AMOUNT: 'Quarterly Amount',
    NUMBER_OF_PAYMENTS: 'No. of Payments',
    NEXT_PAYMENT_DUE: 'Next Payment Due',
    FINANCING_AVAILABLE: 'Financing Available',
    DUE_IN_FULL: 'Due in Full',
    REVIEW_PREMIUM_FINANCE: 'Review and Sign Finance Agreement',
    ASSERT_PF_AGREEMENT:
      'I assert that I have reviewed the agreement and agree to all the terms set forth in the premium finance agreement.',
    ENTER_NAME_NUMBER: 'Enter your name and phone number to submit your electronic signature.',
    AGREE_SIGN: 'Agree and Sign',
    SIGNED_SUCCESSFULLY: 'Signed Successfully',
    SET_UP_PAYMENT_INSTALLMENTS: 'Set Up Payment Installments',
    SET_UP_PAYMENT_INSTALLMENTS_DESCRIPTION:
      'Enter your payment details below to set up installment payments with the finance company. The payments will be automatically debited from the account you provide. Note that the payments will be handled directly by the finance company, not the insurance agency.',
    ACCOUNT_HOLDER_NAME: 'Account Holder Name',
    CONFIRM_ACCOUNT_NUMBER: 'Confirm Account Number',
    ACCOUNT_NUMBER_MISMATCH: 'Account number fields do not match',
    ABA_ROUTING_NUMBER: 'ABA Routing Number',
    CONFIRM_ABA_ROUTING_NUMBER: 'Confirm ABA Routing Number',
    ABA_ROUTING_NUMBER_MISMATCH: 'ABA Routing Number fields do not match',
    PAYMENT_INSTALLMENTS_AUTHORIZATION_DESCRIPTION:
      'I authorize {{company}} to debit {{installmentAmount}} from my bank account {{frequency}} until the premium balance is $0.00.',
    PAYMENT_INSTALLMENTS_AUTHORIZATION_DESCRIPTION_WITH_ACH_AGREEMENT:
      'I agree to the <termsLink>Terms and Conditions</termsLink> of the Commercial ACH Debit Authorization as per {{company}}.',
    CLICK_AND_REVIEW_TERMS: 'Click and review the Terms and Conditions to proceed.',
    SUBMIT: 'Submit',
    AUTHORIZE_PAYMENT_INSTALLMENTS_REQUIRED: 'You must authorize payment installments in order to proceed',
    PF_PAYMENT_CONFIRMATION: 'Thank you for your payment of {{paidTotal}}.',
    PF_PAYMENT_CONFIRMATION_DESCRIPTION:
      'A receipt for payment and a copy of the premium finance agreement has been sent to {{email}} for your records.',
    PF_PAYMENT_AGREEMENT_TERMS:
      'Below is a summary of the financing terms. To set up monthly payments, use the button below or follow the instructions provided in the confirmation email. If you have any questions contact your agent.',
    DOWNLOAD_PF_AGREEMENT: 'Download Premium Finance Agreement',
    FINANCING_SUMMARY: 'Financing Summary',
    TOTAL_PAID_TODAY: 'Total Paid Today',
    DOWN_PAYMENT_METHOD: 'Down payment method',
    BELOW_FINANCE_SUMMARY: 'Below is a summary of the financing terms.',
    DOWN_PAYMENT: 'Down Payment',
  },
  [LocaleCode.en_GB]: {
    ...en_US,
    LOADING: 'Loading',
    MAKE_A_PAYMENT: 'Make a Payment',
    ENTER_PAYMENT_INFO: 'Enter your payment information below.',
    CONTACT_INFORMATION: 'Contact Information',
    PAYMENT_METHOD: 'Payment Method',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    NAME: 'Name',
    BUSINESS_NAME: 'Business Name',
    EMAIL: 'Email Address',
    ORDER_REFERENCE_ID: 'Reference Number',
    PAYMENT_AMOUNT: 'Payment Amount',
    POLICY_NUMBER: 'Policy Number',
    INVOICE_NUMBER: 'Invoice Number',
    AMOUNT: 'Amount',
    PAYMENT_DESCRIPTION: 'Description',
    ORDER_SUMMARY: 'Order Summary',
    PAYMENT_SUMMARY: 'Payment Summary',
    TOTAL_DUE_NOW: 'Total Due Now',
    CURRENCY: 'Currency',
    COPYRIGHT: 'Copyright',
    POLICY_INFORMATION: 'Policy Information',
    CONTINUE_TO_POLICY_INFORMATION: 'Continue to Policy Information',
    CONTINUE_TO_PAYMENT: 'Continue to Payment',
    PAY_NOW: 'Pay Now',
    ERROR_GENERIC: 'Generic Error',
    ERROR_PAYMENT_ERROR: 'Payment Error',
    ERROR_ADYEN_CHECKOUT: 'Checkout Error',
    ERROR_ADYEN_NO_SESSION: 'No Adyen session error',
    ERROR_FIRST_NAME_REQUIRED: 'First name is required',
    ERROR_LAST_NAME_REQUIRED: 'Last name is required',
    ERROR_EMAIL_REQUIRED: 'Email is required',
    ERROR_EMAIL_INVALID: 'Email is invalid',
    ERROR_AMOUNT_REQUIRED: 'Amount is required',
    ERROR_ACCOUNT_NUMBER_REQUIRED: 'Account number is required',
    ERROR_PHONE_NUMBER_REQUIRED: 'Phone number is required',
    ERROR_POSTAL_CODE_REQUIRED: 'Postal code is required',
    ERROR_INVOICE_NUMBER_REQUIRED: 'Invoice number is required',
    PAYMENT_CANCELLED: 'Your payment has been cancelled.',
    ERROR_TOO_MANY_PSP_AUTHORIZATION_ATTEMPTS:
      'Your checkout link/page has exceeded the number of payment attempts (3) permitted and is no longer valid. For your security, you will have to request a new payment link from your Agent or Broker. Thank you!',
    ERROR_DUPLICATE_PAYMENT:
      'This checkout link/page has already been used to process a payment and for security reasons cannot be used again. If you think this is in error, please contact your Agent or Broker immediately for verification. Thank you!',
    ERROR_SESSION_EXPIRED: `Your checkout link/page has not been used within {{paymentSessionExpirationDays}} days and has expired. For your security, you will have to request a new payment link from your Agent or Broker. Thank you!`,
    ERROR_FEE_AMOUNT_BELOW_MINIMUM:
      'The ACH/EFT amount entered is too low to process, the amount entered should be $5.00 or higher to process. Thank you!',
    ERROR_ACH_VALIDATION_FAILED: 'ACH Validation Failed. Please check your details and try again.',
    ERROR_BANK_ACCOUNT_NOT_VALID:
      'We could not verify the information entered, please re-enter your banking information.',
    ERROR_DUPLICATE_PAYMENT_ATTEMPT: 'An existing transaction is already in progress. Please wait momentarily.',
    ERROR_HPP_VALIDATION: 'The account information entered cannot be found. Please verify the details and try again.',
    ERROR_HPP_VALIDATION_BYPASS_ALLOWED:
      'The account information could not be verified.  Select the option to proceed without Account Number to continue making a payment.',
    ERROR_HPP_VALIDATION_INTERNAL:
      'The account information entered cannot be found. Please verify the details and try again. {{id}}',
    ERROR_HPP_VALIDATION_UNKNOWN:
      'The account information entered cannot be found. Please verify the details and try again. {{id}}',
    ERROR_HPP_VALIDATION_NETWORK:
      'The account information entered cannot be found. Please verify the details and try again.',
    ERROR_HPP_VALIDATION_EXCEEDED:
      'The account information could not be verified in the allowed number of attempts. Click "{{buttonName}}" to make a payment without verifying your information.',
    ERROR_HPP_VALIDATION_EXCEEDED_NO_BYPASS:
      'The account information entered could not be verified in the allowed number of attempts. Please contact your agent to make a payment.',
    REFUSED_ERROR_UNKNOWN: 'Unknown error occurred. Please try again.',
    REFUSED_ERROR_EXPIRED_CARD:
      'The expiration date entered indicates that the credit card has expired, and a valid card is required for payment.',
    REFUSED_ERROR_INVALID_CARD_NUMBER:
      'The card number entered does not match the format or length of a valid credit card number. The user may have mistyped or omitted digits.',
    REFUSED_ERROR_INVALID_CVC:
      'Card Verification Value (CVV) or Card Verification Code (CVC) entered does not match the one associated with the credit card. The CVV/CVC is typically a three- or four-digit security code found on the back or front of the card.',
    REFUSED_ERROR_BILLING_ADDRESS_MISMATCH:
      "The billing address provided does not match the address on file for the credit card. The billing address is used to verify the cardholder's identity and prevent fraudulent transactions.",
    REFUSED_ERROR_FRAUD:
      "Oops! We're currently unable to process your payment. Please try again later or use a different payment method. If you continue to experience issues, please contact customer support for assistance. Thank you!",
    CANCEL_THIS_PAYMENT: 'Cancel this Payment',
    EDIT_THIS_PAYMENT: 'Edit this Payment',
    SAVE: 'Save',
    NEXT: 'Next',
    BACK: 'Back',
    SUBTOTAL: 'Subtotal',
    TOTAL: 'Total',
    CONVENIENCE_FEE: 'Convenience Fee',
    TOTAL_DUE_TODAY: 'Total Due Today',
    BREAKDOWN_DESCRIPTION:
      'The total due today is {{totalAmount}}. See the breakdown below and choose a payment method.',
    NON_FINANCED_AMOUNT: 'Non-Financed Amount',
    CONVENIENCE_FEE_DISCLAIMER_BOLD: 'Disclaimer',
    ACH_DIRECT_DEBIT: 'ACH - Direct Debit',
    ACH_DIRECT_DEBIT_TOOLTIP:
      'This payment option will directly debit the amount due from the bank account you provide. Bank account number and routing number is required for this payment option.',
    CONVENIENCE_FEE_DISCLAIMER:
      'If a Convenience Fee is shown above, our systems platform provider, Applied Systems, Inc., will apply a Non-Refundable fee or charge to the total transaction amount payable by you to the extent you elect to make a payment using a credit or debit card or through an ACH transaction. If you do not wish to pay the convenience fee, please contact {Company_Name} for other payment method options.',
    AGREE_CONVENIENCE_FEE: 'Click here to accept the convenience fee and continue with this payment',
    ACCOUNT_NUMBER: 'Account Number',
    ACCOUNT_NUMBER_TOOLTIP:
      'This can also be referred to as Account Lookup Code on your invoice. If you need further assistance contact your agent.',
    PROCEED_WITHOUT_ACCOUNT_NUMBER: 'Proceed without Account Number',
    PHONE_NUMBER: 'Phone Number',
    POSTAL_CODE: 'Postal Code',
    SINGLE_AMOUNT: 'Single Amount',
    PAY_BY_INVOICE: 'Pay by Invoice',
    DUE_DATE: 'Due Date',
    AMOUNT_DUE: 'Amount Due',
    PREMIUM_FINANCE_OFFER_TABLE_HEADER: 'Premium Finance Offer',
    // eslint-disable-next-line no-template-curly-in-string
    PREMIUM_FINANCE_OFFER_TABLE_BODY:
      'Pay later with {{downPaymentAmount}} and {{numberOfInstallments}} {{installmentFrequency}} payments of {{installmentAmount}}.',
    INSTALLMENT_FREQUENCY_TYPE_MONTHLY: 'monthly ',
    INSTALLMENT_FREQUENCY_TYPE_QUARTERLY: 'quarterly ',
    ITEM_NUMBER: 'Item Number',
    DESCRIPTION: 'Description',
    ACCOUNT_INFORMATION: 'Account Information',
    ENTER_YOUR_ACCOUNT_DETAILS: 'Enter your account details below.',
    INVOICE_SELECTION: 'Invoice Selection',
    SELECT_INVOICES_TO_PAY: 'Select the invoices you would like to pay.',
    REVIEW_THE_ITEMS_BELOW: 'Review the items below and enter your payment information.',
    PAYMENT_AGAINST_INVOICE: 'Payment against invoice(s)',
    ERROR_FAILED_TO_LOAD_PAYMENT_METHODS: "Couldn't load stored payment methods",
    ACCOUNT_SETTINGS_MANAGE_ACCOUNT: `Manage Your Account`,
    ACCOUNT_SETTINGS_BACK: `Back to Make a Payment`,
    ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_METHODS: `Payment Methods`,
    ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_HISTORY: `Payment History`,
    ACCOUNT_SETTINGS_TAB_TITLE_MANAGE_AUTOPAY: `Manage Autopay`,
    ACCOUNT_SETTINGS_PH_DATEFILTER_ALL_TIME: `All Time`,
    ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_12: `Last 12 Months`,
    ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_30: `Last 30 Days`,
    ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_7: `Last 7 Days`,
    ACCOUNT_SETTINGS_PH_GRID_DATE: `Date`,
    ACCOUNT_SETTINGS_PH_GRID_INVOICE_NUMBER: `Invoice Number`,
    ACCOUNT_SETTINGS_PH_GRID_DESCRIPTION: `Description`,
    ACCOUNT_SETTINGS_PH_GRID_AMOUNT: `Payment Amount`,
    ACCOUNT_SETTINGS_PH_NOTICE: `Please note the payments represented in this list may not be all payments made, or inclusive of pending payments, unapplied payments or non-digital payments made. Missing payments should be addressed with your account representative.`,
    PAY: 'Pay',
    YOUR_TOTAL: 'Your Total',
    FEE: 'Fee',
    LOG_IN: 'Log In',
    CREATE_ACCOUNT: 'Create an Account',
    USE_PAYMENT_METHOD_ON_FILE: 'Use Payment Method on File',
    YOUR_ACCOUNT_HAS_BEEN_CREATED: 'Your account has been successfully created!',
    ENTER_VERIFICATION_CODE: 'Please check your email for the verification code and enter it below',
    RESEND_CODE: 'Resend Code',
    VERIFICATION_CODE: 'Verification Code',
    VERIFICATION_CODE_REQUIRED: 'Verification code is required',
    VERIFICATION_CODE_NOT_VALID: 'Verification code is not valid or expired',
    LOGIN_MODAL_TO_RECEIVE_CODE: 'To receive a verification code, you must first',
    DONT_RECEIVE_VERIFICATION_CODE:
      "If you haven't received a code, this may indicate that an account has not been created. Please proceed without logging in or create an account.",
    CANCEL: 'Cancel',
    ENTER_YOUR_EMAIL: 'Please enter in your email',
    DONT_HAVE_ACCOUNT: "Don't have an account yet?",
    ALREADY_HAVE_ACCOUNT: 'Already have an account?',
    SUCCESS: 'Success!',
    ERROR: 'Error',
    ERROR_ACCOUNT_NUMBER_NOT_VALID: 'Account number is not valid',
    ACCOUNT_CREATED_SUCCESSFULLY: 'Account created successfully',
    FAILED_TO_CREATE_ACCOUNT: 'Failed to create account',
    LOGIN_SUCCESSFUL: 'You have successfully logged in',
    FAILED_TO_LOGIN: 'Failed to login',
    TOO_MANY_ATTEMPTS: 'Too many attempts. Please try again a bit later.',
    FAILED_TO_SEND_VERIFICATION_CODE: 'Failed to send verification code',
    SEARCH: 'Search',
    DOWNLOAD_TRANSACTIONS_HISTORY: 'Download Transaction History',
    PAYMENT_FLOW_SESSION_ID_MISSING: 'Payment session ID is missing',
    PAYMENT_FLOW_SESSION_FAILED_UNKNOWN: 'Failed to init psp session with response',
    PAYMENT_FLOW_SESSION_ERROR_UNKNOWN: 'Failed to init psp session with error',
    INIT_ADYEN_CHECKOUT_FAILED: 'Failed to init checkout with an error',
    RECAPTCHA_VERIFICATION_FAILED: 'Recaptcha is not ready',
    RECAPTCHA_BROWSER_ERROR: 'Recaptcha is having problems with your browser. Please try a different browser.',
    FIELD_VALIDATION_FAILED: 'Field validation failed',
    PSP_SESSION_UPDATE_FAILED: 'PSP session update failed',
    AGENCY_DETAILS_MISSING: 'Agency details missing',
    FETCH_AGENCY_DETAIL_FAILED_UNKNOWN: 'Failed to get agency details with response',
    FETCH_AGENCY_DETAIL_FAILED_INTERNAL: 'Failed to get agency details with response',
    FETCH_AGENCY_DETAIL_FAILED_NETWORK: 'Failed to get agency details with response',
    FETCH_AGENCY_EMPTY_RESPONSE: 'Failed to fetch agency details: empty response',
    FETCH_AGENCY_FAILED: 'Fialed to fetch agency details with error',
    GET_HPP_SESSION_FAILED_TOKEN_MISSING: 'Get HPP session failed: token missing',
    GET_HPP_SESSION_FAILED_UNKNOWN: 'Failed to get HPP session with response',
    GET_HPP_SESSION_EMPTY_RESPONSE: 'Failed to get HPP session: empty response',
    GET_HPP_SESSION_ERROR_UNKNOWN: 'Failed to get HPP session with error',
    GET_HPP_SESSION_ERROR_INTERNAL: 'Failed to get HPP session with error {{id}}',
    GET_HPP_SESSION_ERROR_NETWORK: 'Failed to get HPP session with error',
    UPDATE_HPP_SESSION_ERROR_NETWORK: 'Failed to update Flow session with network error',
    UPDATE_HPP_SESSION_ERROR_INTERNAL: 'Failed to update Flow session with internal error {{id}}',
    UPDATE_HPP_SESSION_ERROR_UNKNOWN: 'Failed to update Flow session with unknown error',
    VALIDATION_HANDLER_NOT_SET: 'Validation handler not set',
    SELECT_PAY_BY_OPTION:
      'Select a pay by option and enter the required information below to proceed with your payment',
    NO_SELECT_PAY_BY_OPTION: 'Enter the required information below to proceed with your payment.',
    PAY_BY: 'Pay By',
    ERROR_PAYMENT_INITIATION: 'Unable to initiate a payment session.',
    ERROR_TRY: 'Try:',
    ERROR_CHECK_INTERNET: 'Checking your internet connection',
    ERROR_CLEAR_CACHE: 'Clearing browser cache',
    ERROR_TURN_OFF_FIREWALL: 'Turning off your firewall',
    ERROR_CONTACT_IT:
      'If you are on a business-issued system or VPN, and these steps do not resolve the issue, please contact your IT team.',
    PREMIUM_FINANCE_UNAVAILABLE: 'Premium Finance Unavailable',
    PREMIUM_FINANCE_UNAVAILABLE_DESCRIPTION:
      'Premium finance will not be available as a payment method as the amount due exceeds $100,000. If you wish to premium finance, contact your agent or go back and update your selection. Otherwise you may continue to payment options to pay in full.',
    PAY_WITH_FINANCING: 'Pay with Financing',
    PAY_IN_FULL_TODAY: 'Pay in Full Today',
    PAY_WITH_FINANCING_TOOLTIP:
      'This payment option will allow you to set up financing for eligible policies and pay in full for policies that are ineligible for financing.',
    PAYMENTS: 'Payments',
    MONTHLY_PAYMENTS: 'Monthly Payments',
    QUARTERLY_PAYMENTS: 'Quarterly Payments',
    EST_DOWN_PAYMENT: 'Est. Down Payment',
    CONTINUE_TO_DOWN_PAYMENT: 'Continue to Down Payment',
    PAYMENT_OPTIONS: 'Payment Options',
    DOWNPAYMENT: 'Down Payment',
    DOWNPAYMENT_DESCRIPTION: 'Down Payment for Financing',
    RETAINED_PAYMENTS_DESCRIPTION: 'Retained Payments',
    RETAINED_PAYMENTS: 'Retained Payments',
    TBD: 'TBD',
    REVIEW_AND_SIGN_DESCRIPTION:
      'Review and sign the premium finance agreement by clicking the "Review and Sign Agreement" button below. Then continue to the downpayment.',
    PREMIUM_FINANCE_AGREEMENT: 'Premium Finance Agreement',
    REVIEW_AND_SIGN_BUTTON: 'Review and Sign Agreement',
    FINANCING_SUMMARY_TITLE: 'Financing Summary',
    FINANCING_SUMMARY_DESCRIPTION:
      'Below is a summary of your financing terms with {{company}}. After the premium finance agreement has been signed use the button below to set up payment installments. Premium financing will be bound only after a down payment has been made. If you have any questions contact your agent.',
    SET_UP_PAYMENTS_INSTALLMENTS: 'Set Up Payment Installments',
    FINANCE_COMPANY: 'Finance Company',
    FINANCE_QUOTE_NUMBER: 'Finance Quote No',
    AMOUNT_FINANCED: 'Amount Financed',
    INSTALLMENT_AMOUNT: 'Installment Amount',
    MONTHLY_AMOUNT: 'Monthly Amount',
    QUARTERLY_AMOUNT: 'Quarterly Amount',
    NUMBER_OF_PAYMENTS: 'No. of Payments',
    NEXT_PAYMENT_DUE: 'Next Payment Due',
    FINANCING_AVAILABLE: 'Financing Available',
    DUE_IN_FULL: 'Due in Full',
    REVIEW_PREMIUM_FINANCE: 'Review and Sign Finance Agreement',
    ASSERT_PF_AGREEMENT:
      'I assert that I have reviewed the agreement and agree to all the terms set forth in the premium finance agreement.',
    ENTER_NAME_NUMBER: 'Enter your name and phone number to submit your electronic signature.',
    AGREE_SIGN: 'Agree and Sign',
    SIGNED_SUCCESSFULLY: 'Signed Successfully',
    SET_UP_PAYMENT_INSTALLMENTS: 'Set Up Payment Installments',
    SET_UP_PAYMENT_INSTALLMENTS_DESCRIPTION:
      'Enter your payment details below to set up installment payments with the finance company. The payments will be automatically debited from the account you provide. Note that the payments will be handled directly by the finance company, not the insurance agency.',
    ACCOUNT_HOLDER_NAME: 'Account Holder Name',
    CONFIRM_ACCOUNT_NUMBER: 'Confirm Account Number',
    ACCOUNT_NUMBER_MISMATCH: 'Account number fields do not match',
    ABA_ROUTING_NUMBER: 'ABA Routing Number',
    CONFIRM_ABA_ROUTING_NUMBER: 'Confirm ABA Routing Number',
    ABA_ROUTING_NUMBER_MISMATCH: 'ABA Routing Number fields do not match',
    PAYMENT_INSTALLMENTS_AUTHORIZATION_DESCRIPTION:
      'I authorize {{company}} to debit {{installmentAmount}} from my bank account {{frequency}} until the premium balance is $0.00.',
    PAYMENT_INSTALLMENTS_AUTHORIZATION_DESCRIPTION_WITH_ACH_AGREEMENT:
      'I agree to the <termsLink>Terms and Conditions</termsLink> of the Commercial ACH Debit Authorization as per {{company}}.',
    CLICK_AND_REVIEW_TERMS: 'Click and review the Terms and Conditions to proceed.',
    SUBMIT: 'Submit',
    AUTHORIZE_PAYMENT_INSTALLMENTS_REQUIRED: 'You must authorize payment installments in order to proceed',
    PF_PAYMENT_CONFIRMATION: 'Thank you for your payment of {{paidTotal}}.',
    PF_PAYMENT_CONFIRMATION_DESCRIPTION:
      'A receipt for payment and a copy of the premium finance agreement has been sent to {{email}} for your records.',
    PF_PAYMENT_AGREEMENT_TERMS:
      'Below is a summary of the financing terms. To set up monthly payments, use the button below or follow the instructions provided in the confirmation email. If you have any questions contact your agent.',
    DOWNLOAD_PF_AGREEMENT: 'Download Premium Finance Agreement',
    FINANCING_SUMMARY: 'Financing Summary',
    TOTAL_PAID_TODAY: 'Total Paid Today',
    DOWN_PAYMENT_METHOD: 'Down payment method',
    BELOW_FINANCE_SUMMARY: 'Below is a summary of the financing terms.',
    DOWN_PAYMENT: 'Down Payment',
  },
  [LocaleCode.fr_CA]: {
    LOADING: 'Chargement',
    MAKE_A_PAYMENT: 'Effectuer un paiement',
    ENTER_PAYMENT_INFO: 'Saisissez vos informations de paiement ci-dessous.',
    ENTER_PAYMENT_INFORMATION: 'Entrez les informations ci-dessous pour effectuer un paiement.',
    CONTACT_INFORMATION: 'Coordonnées',
    PAYMENT_METHOD: 'Mode de paiement',
    FIRST_NAME: 'Prénom',
    LAST_NAME: 'Nom',
    NAME: 'Nom',
    BUSINESS_NAME: 'Nom d’entreprise',
    EMAIL: 'Courriel',
    ORDER_REFERENCE_ID: 'Numéro de référence',
    PAYMENT_AMOUNT: 'Montant du paiement',
    POLICY_NUMBER: 'Numéro de la police',
    INVOICE_NUMBER: 'Numéro de facture',
    AMOUNT: 'Montant',
    PAYMENT_DESCRIPTION: 'Descriptions',
    ORDER_SUMMARY: 'Sommaire de la commande',
    PAYMENT_SUMMARY: 'Sommaire du paiement',
    TOTAL_DUE_NOW: 'Total dû maintenant',
    CURRENCY: 'Devise',
    COPYRIGHT: 'Tous droits réservés',
    POLICY_INFORMATION: 'Informations sur la police',
    CONTINUE_TO_POLICY_INFORMATION: 'Continuer vers les informations sur la police',
    PAY_NOW: 'Payez maintenant',
    CONTINUE_TO_PAYMENT: 'Continuer vers le paiement',
    ERROR_GENERIC: 'Erreur générique',
    ERROR_PAYMENT_ERROR: 'Erreur de paiement',
    ERROR_ADYEN_CHECKOUT: 'Erreur lors du passage à la caisse',
    ERROR_ADYEN_NO_SESSION: 'Pas d’erreur de session Adyen',
    ERROR_FIRST_NAME_REQUIRED: 'Le prénom est requis',
    ERROR_LAST_NAME_REQUIRED: 'Le nom est requis',
    ERROR_EMAIL_REQUIRED: 'Le courriel est requis',
    ERROR_EMAIL_INVALID: 'Le courriel est non valide',
    ERROR_AMOUNT_REQUIRED: 'Montant est requis',
    ERROR_ACCOUNT_NUMBER_REQUIRED: 'Le numéro de compte est requis',
    ERROR_PHONE_NUMBER_REQUIRED: 'Le numéro de téléphone est requis',
    ERROR_POSTAL_CODE_REQUIRED: 'Le code postal est requis',
    ERROR_INVOICE_NUMBER_REQUIRED: 'Le numéro de facture est requis',
    ERROR_HPP_VALIDATION:
      'Les informations de compte saisies sont introuvables. Veuillez vérifier les détails et réessayer.',
    ERROR_HPP_VALIDATION_BYPASS_ALLOWED:
      "Les informations du compte n'ont pas pu être vérifiées. Sélectionnez l’option permettant de procéder sans numéro de compte pour continuer à effectuer un paiement.",
    ERROR_HPP_VALIDATION_INTERNAL:
      'Les informations de compte saisies sont introuvables. Veuillez vérifier les détails et réessayer. {{id}}',
    ERROR_HPP_VALIDATION_UNKNOWN:
      'Les informations de compte saisies sont introuvables. Veuillez vérifier les détails et réessayer. {{id}}',
    ERROR_HPP_VALIDATION_NETWORK:
      'Les informations de compte saisies sont introuvables. Veuillez vérifier les détails et réessayer.',
    ERROR_HPP_VALIDATION_EXCEEDED:
      'Les informations du compte n\'ont pas pu être vérifiées après le nombre de tentatives autorisé. Cliquez sur "{{buttonName}}" pour effectuer un paiement sans vérifier vos informations.',
    ERROR_HPP_VALIDATION_EXCEEDED_NO_BYPASS:
      'Les informations de compte saisies n’ont pas pu être vérifiées dans le nombre de tentatives autorisées. Veuillez contacter votre agent pour effectuer un paiement.',
    PAYMENT_CANCELLED: 'Your payment has been cancelled.',
    ERROR_TOO_MANY_PSP_AUTHORIZATION_ATTEMPTS:
      "Votre lien/page de paiement a dépassé le nombre de tentatives de paiement (5) autorisé et n'est plus valide. Pour votre sécurité, vous devrez demander un nouveau lien de paiement à votre agent ou courtier. Merci!",
    ERROR_DUPLICATE_PAYMENT:
      "Ce lien/page de paiement a déjà été utilisé pour traiter un paiement et pour des raisons de sécurité, il ne peut plus être utilisé. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter immédiatement votre agent ou votre courtier pour vérification. Merci!",
    ERROR_SESSION_EXPIRED: `Votre lien/page de paiement n'a pas été utilisé dans les {{paymentSessionExpirationDays}} jours et a expiré. Pour votre sécurité, vous devrez demander un nouveau lien de paiement à votre agent ou courtier. Merci!`,
    ERROR_FEE_AMOUNT_BELOW_MINIMUM:
      'Le montant ACH/EFT saisi est trop faible pour être traité, le montant saisi doit être de 5,00 $ ou plus pour être traité. Merci!',
    ERROR_ACH_VALIDATION_FAILED: 'La validation ACH a échoué. Veuillez vérifier vos coordonnées et réessayer.',
    ERROR_BANK_ACCOUNT_NOT_VALID:
      'We could not verify the information entered, please re-enter your banking information.',
    ERROR_DUPLICATE_PAYMENT_ATTEMPT: 'Une transaction existante est déjà en cours. Veuillez patienter un moment.',
    ERROR_MERCHANT_ACCOUNT_NOT_ACTIVE:
      "Le compte marchand n'est pas actif. Veuillez contacter votre agent pour effectuer un paiement.",
    REFUSED_ERROR_UNKNOWN: "Une erreur inconnue s'est produite. Veuillez réessayer.",
    REFUSED_ERROR_EXPIRED_CARD:
      "La date d'expiration saisie indique que la carte de crédit a expiré et qu'une carte valide est requise pour le paiement.",
    REFUSED_ERROR_INVALID_CARD_NUMBER:
      "Le numéro de carte saisi ne correspond pas au format ou à la longueur d'un numéro de carte de crédit valide. L'utilisateur peut avoir mal saisi ou omis des chiffres.",
    REFUSED_ERROR_INVALID_CVC:
      "La valeur de vérification de la carte (CVV) ou le code de vérification de la carte (CVC) saisi ne correspond pas à celui associé à la carte de crédit. Le CVV/CVC est généralement un code de sécurité à trois ou quatre chiffres qui se trouve au dos ou à l'avant de la carte.",
    REFUSED_ERROR_BILLING_ADDRESS_MISMATCH:
      "L'adresse de facturation fournie ne correspond pas à l'adresse enregistrée pour la carte de crédit. L'adresse de facturation est utilisée pour vérifier l'identité du titulaire de la carte et empêcher les transactions frauduleuses.",
    REFUSED_ERROR_FRAUD:
      "Oups! Nous ne sommes pas en mesure de traiter votre paiement actuellement. Veuillez réessayer plus tard ou utiliser un autre mode de paiement. Si vous rencontrez toujours des problèmes, veuillez contacter le support client pour obtenir de l'aide. Merci!",
    REFUSED_ERROR_REASON: 'Paiement refusé: {{refusalReason}}.',
    CANCEL_THIS_PAYMENT: 'Annuler ce paiement',
    EDIT_THIS_PAYMENT: 'Modifier ce paiement',
    SAVE: 'Enregistrer',
    NEXT: 'Suivant',
    BACK: 'Retour',
    SUBTOTAL: 'Sous total',
    TOTAL: 'Total',
    CONVENIENCE_FEE: 'Frais de commodité',
    TOTAL_DUE_TODAY: 'Total dû aujourd’hui',
    BREAKDOWN_DESCRIPTION:
      'Le total dû aujourd’hui est de {{totalAmount}}. Consultez le détail ci-dessous et choisissez un mode de paiement.',
    NON_FINANCED_AMOUNT: 'Montant non financé',
    CONVENIENCE_FEE_DISCLAIMER_BOLD: 'Disclaimer',
    ACH_DIRECT_DEBIT: 'ACH - Prélèvement automatique',
    ACH_DIRECT_DEBIT_TOOLTIP:
      'Cette option de paiement débitera directement le montant dû du compte bancaire que vous fournissez. Le numéro de compte bancaire et le numéro de routage sont requis pour cette option de paiement.',
    ACCEPT_CC_FEE: `J'accepte les frais associés au paiement par carte bancaire`,
    CONVENIENCE_FEE_DISCLAIMER:
      "Si des frais de commodité sont indiqués ci-dessus, notre fournisseur de plate-forme de systèmes, Applied Systems, Inc., appliquera des frais ou des charges non remboursables au montant total de la transaction que vous devez payer dans la mesure où vous choisissez d'effectuer un paiement en utilisant un crédit carte. Si vous ne souhaitez pas payer les frais de commodité, veuillez contacter {Company_Name} pour connaître d'autres options de mode de paiement.",
    AGREE_CONVENIENCE_FEE: 'Cliquez ici pour accepter les frais de commodité et continuer avec ce paiement',
    ACCOUNT_NUMBER: 'Numéro de compte',
    CHOOSE_YOUR_PAYMENT_METHOD: 'Choisissez votre méthode de paiement',
    ACCOUNT_NUMBER_TOOLTIP:
      "Cela peut également être appelé code de recherche de compte sur votre facture. Si vous avez besoin d'aide supplémentaire, contactez votre courtier.",
    PROCEED_WITHOUT_ACCOUNT_NUMBER: 'Continuer sans numéro de compte',
    PHONE_NUMBER: 'Numéro de téléphone',
    POSTAL_CODE: 'Code postal',
    SINGLE_AMOUNT: 'Montant unique',
    PAY_BY_INVOICE: 'Payer par facture',
    DUE_DATE: 'Date d’échéance',
    AMOUNT_DUE: 'Montant dû',
    PREMIUM_FINANCE_OFFER_TABLE_HEADER: 'Offre de Financement Premium',
    // eslint-disable-next-line no-template-curly-in-string
    PREMIUM_FINANCE_OFFER_TABLE_BODY:
      'Payer plus tard avec {{downPaymentAmount}} et {{numberOfInstallments}} paiements {{installmentFrequency}} de {{installmentAmount}}.',
    INSTALLMENT_FREQUENCY_TYPE_MONTHLY: 'mensuel ',
    INSTALLMENT_FREQUENCY_TYPE_QUARTERLY: 'trimestriel ',
    ITEM_NUMBER: 'Numéro d’article',
    DESCRIPTION: 'Description',
    ACCOUNT_INFORMATION: 'Informations sur le compte',
    ENTER_YOUR_ACCOUNT_DETAILS: 'Entrez vos coordonnées ci-dessous.',
    INVOICE_SELECTION: 'Sélection de facture',
    SELECT_INVOICES_TO_PAY: 'Sélectionnez les factures que vous souhaitez payer.',
    REVIEW_THE_ITEMS_BELOW: 'Vérifiez les éléments ci-dessous et saisissez vos informations de paiement.',
    PAYMENT_AGAINST_INVOICE: 'Paiement contre facture(s)',
    ERROR_FAILED_TO_LOAD_PAYMENT_METHODS: 'Impossible de charger les modes de paiement stockés',
    ACCOUNT_SETTINGS_MANAGE_ACCOUNT: `Gérez votre compte`,
    ACCOUNT_SETTINGS_BACK: `Retour à Effectuer un paiement`,
    ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_METHODS: `Moyens de paiement`,
    ACCOUNT_SETTINGS_TAB_TITLE_PAYMENT_HISTORY: `Historique des paiements`,
    ACCOUNT_SETTINGS_TAB_TITLE_MANAGE_AUTOPAY: `Gérer le prélèvement automatique`,
    ACCOUNT_SETTINGS_PH_DATEFILTER_ALL_TIME: `Tout le temps`,
    ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_12: '12 derniers mois',
    ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_30: '30 derniers jours',
    ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_7: '7 derniers jours',
    ACCOUNT_SETTINGS_PH_GRID_DATE: `Date`,
    ACCOUNT_SETTINGS_PH_GRID_INVOICE_NUMBER: `Numéro de facture`,
    ACCOUNT_SETTINGS_PH_GRID_DESCRIPTION: `Description`,
    ACCOUNT_SETTINGS_PH_GRID_AMOUNT: `Montant du paiement`,
    ACCOUNT_SETTINGS_PH_NOTICE: `Veuillez noter que les paiements représentés dans cette liste peuvent ne pas inclure tous les paiements effectués, ou ne pas inclure les paiements en attente, les paiements non appliqués ou les paiements non numériques effectués. Les paiements manquants doivent être signalés à votre représentant de compte.`,
    PAY: 'Payer',
    YOUR_TOTAL: 'Votre total',
    FEE: 'Frais',
    LOG_IN: 'Se connecter',
    CREATE_ACCOUNT: 'Créer un compte',
    USE_PAYMENT_METHOD_ON_FILE: 'Utiliser le mode de paiement enregistré dans le dossier',
    YOUR_ACCOUNT_HAS_BEEN_CREATED: 'Votre compte a été créé avec succès !',
    ENTER_VERIFICATION_CODE:
      'Veuillez vérifier votre courrier électronique pour le code de vérification et saisissez-le ci-dessous',
    RESEND_CODE: 'Renvoyer le code',
    VERIFICATION_CODE: 'Code de vérification',
    VERIFICATION_CODE_REQUIRED: 'Un code de vérification est requis',
    VERIFICATION_CODE_NOT_VALID: "Le code de vérification n'est pas valide ou a expiré",
    LOGIN_MODAL_TO_RECEIVE_CODE: "Pour recevoir un code de vérification, vous devez d'abord",
    DONT_RECEIVE_VERIFICATION_CODE:
      "Si vous n'avez pas reçu de code, cela peut indiquer qu'un compte n'a pas été créé. Veuillez continuer sans vous connecter ou créer un compte.",
    CANCEL: 'Annuler',
    ENTER_YOUR_EMAIL: 'Veuillez saisir votre email',
    DONT_HAVE_ACCOUNT: "Vous n'avez pas encore de compte ?",
    ALREADY_HAVE_ACCOUNT: 'Vous avez déjà un compte ?',
    SUCCESS: 'Succès!',
    ERROR: 'Erreur',
    ERROR_ACCOUNT_NUMBER_NOT_VALID: "Le numéro de compte n'est pas valide",
    ACCOUNT_CREATED_SUCCESSFULLY: 'Compte créé avec succès',
    FAILED_TO_CREATE_ACCOUNT: 'Échec de la création du compte',
    LOGIN_SUCCESSFUL: 'Vous êtes connecté avec succès',
    FAILED_TO_LOGIN: 'Échec de la connexion',
    TOO_MANY_ATTEMPTS: 'Trop de tentatives. Veuillez réessayer un peu plus tard.',
    FAILED_TO_SEND_VERIFICATION_CODE: "Échec de l'envoi du code de vérification",
    SEARCH: 'Rechercher',
    DOWNLOAD_TRANSACTIONS_HISTORY: 'Télécharger l’historique des transactions',
    STORED_PAYMENT_METHOD_DELETED: 'Le mode de paiement enregistré a été supprimé avec succès',
    FAILED_TO_DELETE_STORED_PAYMENT_METHOD: 'Échec de la suppression du mode de paiement enregistré',
    PAYMENT_METHOD_CARDHOLDER_NAME: 'Nom du titulaire de la carte',
    PAYMENT_METHOD_CARD_NUMBER: 'Numéro de carte',
    PAYMENT_METHOD_EXPIRY: 'Expiration',
    PAYMENT_METHOD_CARD_TYPE: 'Type',
    NO_DATA: 'Aucune donnée',
    REMOVE_PAYMENT_METHOD: 'Supprimer le mode de paiement',
    PAYMENT_METHOD_OWNER_NAME: 'Nom du propriétaire',
    PAYMENT_METHOD_ACCOUNT_NICKNAME: 'Pseudo du compte',
    PAYMENT_METHOD_ACCOUNT_NUMBER: 'Numéro de compte',
    PAYMENT_METHOD_ROUTING_NUMBER: 'Nom de la banque',
    DELETE: 'Supprimer',
    DELETE_PAYMENT_METHOD_CONFIRMATION_TITLE: 'Supprimer le mode de paiement enregistré',
    DELETE_PAYMENT_METHOD_CONFIRMATION: 'Êtes-vous sûr de vouloir supprimer ce mode de paiement enregistré?',
    PAYMENT_METHOD_NICKNAME: 'Pseudo du mode de paiement',
    EDIT_STORED_PAYMENT_METHOD: 'Modifier le mode de paiement',
    EDIT_STORED_PAYMENT_METHOD_NICKNAME: 'Modifier le pseudo',
    ADD_PAYMENT_METHOD: 'Ajouter un mode de paiement',
    ACH: 'ACH',
    CREDIT_CARDS: 'Cartes de crédit',
    CREDIT_CARD: 'Carte de crédit',
    SELECT_A_CARD: 'Sélectionnez une carte',
    SELECT_AN_ACCOUNT: 'Sélectionnez un compte',
    ENTER_A_NEW_PAYMENT_METHOD: 'Entrez un nouveau mode de paiement',
    PAYMENT_METHOD_ADDED: 'Le mode de paiement a été ajouté avec succès',
    PAYMENT_METHOD_FAILED_TO_ADD: "Échec de l'ajout du mode de paiement",
    PAYMENT_METHOD_FAIL_TO_INITIALIZE_FORM: "Échec de l'initialisation du formulaire",
    PAYMENT_FLOW_SESSION_ID_MISSING: "L'ID de session de paiement est manquant",
    PAYMENT_CONFIRMATION: 'Merci pour votre paiement de {{totalAmount}}',
    PAYMENT_CONFIRMATION_DESCRIPTION: `Un reçu de paiement a été envoyé à {{email}} pour vos dossiers. Ci-dessous un récapitulatif de votre paiement.`,
    INVOICE: 'Facture',
    TOTAL_PAID: 'Total payé',
    PAID: 'Payé',
    PRINT_CONFIRMATION: 'Imprimer la confirmation',
    CONFIRMATION_SUCCESS_ALT_TEXT: 'Coche de paiement réussi',
    ENDING_WITH: 'se terminant {{lastFourDigits}}',
    PAYMENT_FLOW_SESSION_FAILED_UNKNOWN: "Échec de l'initialisation de la session PSP avec réponse",
    PAYMENT_FLOW_SESSION_ERROR_UNKNOWN: 'Failed to init psp session with error',
    INIT_ADYEN_CHECKOUT_FAILED: "Échec de l'initialisation de la session PSP avec erreur",
    RECAPTCHA_VERIFICATION_FAILED: "Recaptcha n'est pas prêt",
    RECAPTCHA_BROWSER_ERROR:
      'Recaptcha rencontre des problèmes avec votre navigateur. Veuillez essayer un autre navigateur.',
    RECAPTCHA_BROWSER_ERROR_WITH_ID:
      "Recaptcha rencontre des problèmes avec votre navigateur. Veuillez essayer un autre navigateur.\n\nCode d'erreur {{id}}",
    FIELD_VALIDATION_FAILED: 'La validation du champ a échoué',
    PSP_SESSION_UPDATE_FAILED: 'La mise à jour de la session PSP a échoué',
    AGENCY_DETAILS_MISSING: "Détails de l'agence manquants",
    FETCH_AGENCY_DETAIL_FAILED_UNKNOWN: "Impossible d'obtenir les détails de l'agence avec réponse",
    FETCH_AGENCY_DETAIL_FAILED_INTERNAL: "Impossible d'obtenir les détails de l'agence avec réponse",
    FETCH_AGENCY_DETAIL_FAILED_NETWORK: "Impossible d'obtenir les détails de l'agence avec réponse",
    UNEXPECTED_ERROR_CODE: "Unexpected Error #{{code}} \n\nCode d'erreur {{id}}",
    ERROR_MAKE_PAYMENT_INTERNAL: "Une erreur s'est produite lors du paiement",
    ERROR_MAKE_PAYMENT_UNKNOWN: "Une erreur s'est produite lors du paiement",
    ERROR_MAKE_PAYMENT_NETWORK: "Une erreur s'est produite lors du paiement",
    FETCH_AGENCY_EMPTY_RESPONSE: "Échec de la récupération des détails de l'agence : réponse vide",
    FETCH_AGENCY_FAILED: "Validé pour récupérer les détails de l'agence avec une erreur",
    GET_HPP_SESSION_FAILED_TOKEN_MISSING: "Échec de l'obtention de la session HPP : jeton manquant",
    GET_HPP_SESSION_FAILED_UNKNOWN: 'Failed to get HPP session with response',
    GET_HPP_SESSION_EMPTY_RESPONSE: "Échec de l'obtention de la session HPP : réponse vide",
    GET_HPP_SESSION_ERROR_UNKNOWN: "Échec de l'obtention de la session HPP avec erreur",
    GET_HPP_SESSION_ERROR_INTERNAL: "Échec de l'obtention de la session avec erreur {{id}}",
    GET_HPP_SESSION_ERROR_NETWORK: "Échec de l'obtention de la session avec erreur",
    UPDATE_HPP_SESSION_ERROR_NETWORK: "Échec de la mise à jour de la session de flux avec l'erreur réseau",
    UPDATE_HPP_SESSION_ERROR_INTERNAL: 'Échec de la mise à jour de la session de flux avec une erreur interne {{id}}',
    UPDATE_HPP_SESSION_ERROR_UNKNOWN: 'Échec de la mise à jour de la session de flux avec une erreur inconnue',
    VALIDATION_HANDLER_NOT_SET: 'Gestionnaire de validation non défini',
    SELECT_PAY_BY_OPTION:
      'Sélectionnez une option de paiement et entrez les informations requises ci-dessous pour procéder à votre paiement.',
    NO_SELECT_PAY_BY_OPTION: 'Entrez les informations requises ci-dessous pour procéder à votre paiement.',
    PAY_BY: 'Payer Par',
    ERROR_PAYMENT_INITIATION: 'Impossible de démarrer une session de paiement.',
    ERROR_TRY: 'Essayez ce qui suit :',
    ERROR_CHECK_INTERNET: 'Vérifier votre connexion Internet',
    ERROR_CLEAR_CACHE: 'Vider le cache de votre navigateur',
    ERROR_TURN_OFF_FIREWALL: 'Désactiver votre pare-feu',
    ERROR_CONTACT_IT:
      'Si vous utilisez un système émis par une entreprise ou un réseau privé virtuel et que votre problème n’est pas résolu après avoir effectué les étapes ci-dessus, veuillez communiquer avec votre équipe du service informatique.',
    PREMIUM_FINANCE_UNAVAILABLE: 'Financement premium indisponible',
    PREMIUM_FINANCE_UNAVAILABLE_DESCRIPTION:
      'Le financement des primes ne sera pas disponible comme mode de paiement car le montant dû dépasse 100 000 $. Si vous souhaitez un financement premium, contactez votre agent ou revenez en arrière et mettez à jour votre sélection. Sinon, vous pouvez continuer à utiliser les options de paiement pour payer la totalité.',
    PAY_WITH_FINANCING: 'Payer avec le financement',
    PAY_IN_FULL_TODAY: 'Payer la totalité aujourd’hui',
    PAY_WITH_FINANCING_TOOLTIP:
      'Cette option de paiement vous permettra de mettre en place un financement pour les polices admissibles et de payer intégralement les polices qui ne sont pas admissibles au financement.',
    PAYMENTS: 'versements',
    MONTHLY_PAYMENTS: 'Paiements mensuels',
    QUARTERLY_PAYMENTS: 'Paiements trimestriels',
    EST_DOWN_PAYMENT: 'Acompte estimé',
    CONTINUE_TO_DOWN_PAYMENT: 'Continuer vers l’acompte',
    PAYMENT_OPTIONS: 'Options de paiement',
    DOWNPAYMENT: 'Acompte',
    DOWNPAYMENT_DESCRIPTION: 'Acompte pour le financement',
    RETAINED_PAYMENTS_DESCRIPTION: '',
    RETAINED_PAYMENTS: '',
    TBD: 'À déterminer',
    REVIEW_AND_SIGN_DESCRIPTION:
      'Réviser et signer l’accord de Financement prime en cliquant sur le bouton « Réviser et signer l’accord » ci-dessous. Puis continuez vers l’acompte.',
    PREMIUM_FINANCE_AGREEMENT: 'Accord de Financement Premium',
    REVIEW_AND_SIGN_BUTTON: 'Réviser et approuver l’accord',
    FINANCING_SUMMARY_TITLE: 'Résumé de financement',
    FINANCING_SUMMARY_DESCRIPTION:
      'Vous trouverez ci-dessous un Résumé de votre Conditions de financement avec {{company}}. Le financement de primes ne sera lié qu’au versement d’un acompte. Si vous avez des questions, communiquez avec votre agent.',
    SET_UP_PAYMENTS_INSTALLMENTS: 'Configurer lespaiements par versement',
    FINANCE_COMPANY: 'Compagnie financière',
    FINANCE_QUOTE_NUMBER: 'Numéro de soumission de financement',
    AMOUNT_FINANCED: 'Montant financé',
    INSTALLMENT_AMOUNT: 'Montant des versements',
    MONTHLY_AMOUNT: 'Montant mensuel',
    QUARTERLY_AMOUNT: 'Montant des versements',
    NUMBER_OF_PAYMENTS: 'Nombre de paiements',
    NEXT_PAYMENT_DUE: 'Prochain paiement dû',
    FINANCING_AVAILABLE: 'Financement disponible',
    DUE_IN_FULL: 'Due en totalité',
    REVIEW_PREMIUM_FINANCE: "Réviser et signer l'accord financier",
    ASSERT_PF_AGREEMENT:
      "Je déclare avoir examiné l'accord et accepter tous les termes énoncés dans l'accord de financement des primes.",
    ENTER_NAME_NUMBER: 'Entrez votre nom et votre numéro de téléphone pour soumettre votre signature électronique.',
    AGREE_SIGN: 'Accepter et Signer',
    SIGNED_SUCCESSFULLY: 'Signé avec succès',
    SET_UP_PAYMENT_INSTALLMENTS: 'Configurer les paiements par versements ',
    SET_UP_PAYMENT_INSTALLMENTS_DESCRIPTION:
      'Saisissez vos détails de paiement ci-dessous pour mettre en place des paiements échelonnés avec la compagnie financière. Les paiements seront automatiquement débités du compte que vous avez indiqué. Note que les paiements seront traités directement par le Compagnie financière, et non par l’agence d’assurance.',
    ACCOUNT_HOLDER_NAME: 'Nom du titulaire du compte',
    CONFIRM_ACCOUNT_NUMBER: 'Confirmer le nom du titulaire du compte',
    ACCOUNT_NUMBER_MISMATCH: 'Les champs de numéro de compte ne correspondent pas',
    ABA_ROUTING_NUMBER: 'Numéro d’acheminement ABA',
    CONFIRM_ABA_ROUTING_NUMBER: 'Confirmer le numéro de routage ABA',
    ABA_ROUTING_NUMBER_MISMATCH: 'Les champs de numéro de routage ABA ne correspondent pas',
    PAYMENT_INSTALLMENTS_AUTHORIZATION_DESCRIPTION:
      'J’autorise {{company}} à débiter {{installmentAmount}} de mon compte bancaire {{frequency}} jusqu’à ce que le solde de la prime atteigne 0,00 $.',
    PAYMENT_INSTALLMENTS_AUTHORIZATION_DESCRIPTION_WITH_ACH_AGREEMENT:
      "Je suis d'accord avec les <termsLink>Termes et Conditions</termsLink> de l'Autorisation de Débit Commercial ACH selon {{company}}.",
    CLICK_AND_REVIEW_TERMS: 'Cliquez et examinez les Termes et Conditions pour continuer.',
    SUBMIT: 'Soumettre',
    AUTHORIZE_PAYMENT_INSTALLMENTS_REQUIRED: 'Vous devez autoriser les versements échelonnés pour continuer',
    PF_PAYMENT_CONFIRMATION: 'Merci pour votre paiement de {{paidTotal}}.',
    PF_PAYMENT_CONFIRMATION_DESCRIPTION:
      "Un reçu de paiement ainsi qu'une copie de l'accord de financement de prime ont été envoyés à {{email}} pour vos dossiers.",
    PF_PAYMENT_AGREEMENT_TERMS:
      "Voici un résumé des modalités de financement. Pour mettre en place des paiements mensuels, utilisez le bouton ci-dessous ou suivez les instructions fournies dans l'email de confirmation. Si vous avez des questions, contactez votre agent.",
    DOWNLOAD_PF_AGREEMENT: "Télécharger l'accord de financement de prime",
    FINANCING_SUMMARY: 'Résumé du financement',
    TOTAL_PAID_TODAY: "Total payé aujourd'hui",
    DOWN_PAYMENT_METHOD: 'Méthode de paiement du versement initial',
    BELOW_FINANCE_SUMMARY: 'Ci-dessous un résumé des conditions de financement.',
    DOWN_PAYMENT: 'Acompte',
  },
} as const;
